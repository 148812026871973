import React, { useEffect, useState } from "react";
import { Form, Input, Button, DatePicker, Select, InputNumber } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { paymentModeList } from "../helpers/lookups";
import { ResDate } from "../helpers/formatter";

const InvoicePaymentAdd = (props) => {
  const [data, setData] = useState("");
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const setDefaultData = (data) => {
    form.setFieldsValue({
      paymentDate: data?.paymentDate && dayjs(data?.paymentDate),
      paidAmount: data?.paidAmount || 0,
      paymentMode: data?.paymentMode || "",
      paymentGateway: data?.paymentGateway || "",
      paymentReference: data?.paymentReference || "",
      paymentComments: data?.paymentComments || "",
      paymentCurrency: data?.paymentCurrency || "",
      conversionRate: data?.conversionRate || "",
      pendingAmount: data?.pendingAmount || 0,
      paymentStatus: data?.paymentStatus || "",
      paymentGatewayCharges: data?.paymentGatewayCharges || 0,
      otherCharges: data?.otherCharges || 0,
      paymentTime: data?.paymentTime && dayjs(data?.paymentTime),
    });
  };

  const onFinish = (values) => {
    let payment = values;

    payment.paymentTime = ResDate(values?.paymentDate);
    payment.paymentDate = ResDate(values?.paymentDate);

    if (props?.isEditModePayment) {
      payment.guId = props?.guId;
      payment.invoicePaymentId = props?.invoicePaymentId;
      props.handleUpdatePayment(payment);
    } else {
      payment.guId = generateGUID();
      props.handleAddPayment(payment);
    }
  };

  const generateGUID = () => {
    const s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };

  useEffect(() => {
    props?.isEditModePayment && setDefaultData(props);
    form.setFieldsValue({ totalAmount: props.invoiceTotal || 0 });
  }, []);

  return (
    <React.Fragment>
      <Form
        name="invoice-payment-add"
        form={form}
        {...layout}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          paymentDate: dayjs(),
          paymentTime: dayjs(),
          paymentMode: "Case",
          totalAmount: 0,
        }}
      >
        <Form.Item label="Payment Date" name="paymentDate">
          <DatePicker format={"DD/MM/YYYY"} allowClear={false} />
        </Form.Item>
        <Form.Item label="Invoice Amount" name="totalAmount">
          <InputNumber
            placeholder="Total Amount"
            style={{ minWidth: "100%" }}
            disabled
          />
        </Form.Item>
        <Form.Item label="Payment Amount" name="paidAmount">
          <InputNumber
            placeholder="Payment Amount"
            style={{ minWidth: "100%" }}
          />
        </Form.Item>
        <Form.Item label="Payment Mode" name="paymentMode">
          <Select
            placeholder="Select Payment Mode"
            options={paymentModeList}
          ></Select>
        </Form.Item>
        <Form.Item label="Payment Gateway" name="paymentGateway">
          <Input placeholder="Payment Gateway" />
        </Form.Item>
        <Form.Item label="Payment Reference" name="paymentReference">
          <Input placeholder="Payment Reference" />
        </Form.Item>
        <Form.Item label="Payment Comments" name="paymentComments">
          <Input.TextArea placeholder="Comments" />
        </Form.Item>

        <Form.Item label="Payment Currency" name="paymentCurrency" hidden>
          <Input placeholder="Payment Currency" />
        </Form.Item>
        <Form.Item label="Conversion Rate" name="conversionRate" hidden>
          <Input placeholder="Conversion Rate" />
        </Form.Item>

        <Form.Item label="Pending Amount" name="pendingAmount" hidden>
          <InputNumber placeholder="Pending Amount" />
        </Form.Item>
        <Form.Item label="Payment Status" name="paymentStatus" hidden>
          <Input placeholder="Payment tStatus" />
        </Form.Item>
        <Form.Item label="Gateway Charges" name="paymentGatewayCharges" hidden>
          <Input placeholder="Gateway Charges" />
        </Form.Item>
        <Form.Item label="Other Charges" name="otherCharges" hidden>
          <Input placeholder="Other Charges" />
        </Form.Item>
        <Form.Item label="Payment Time" name="paymentTime" hidden>
          <DatePicker format={"DD/MM/YYYY"} allowClear={false} />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
          className="btn-row"
        >
          <Button
            icon={
              !props?.isEditModePayment ? <PlusOutlined /> : <EditOutlined />
            }
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {!props?.isEditModePayment ? "Add Payment" : "Update Payment"}
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default InvoicePaymentAdd;
