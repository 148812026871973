import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Drawer,
  Row,
  Col,
  InputNumber,
  Checkbox,
  Select,
  Table,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { gstTypeList } from "../helpers/lookups";
import InvoicePassengerAdd from "./invoice-passenger-add";
import { ResDate } from "../helpers/formatter";
import fetchData from "../services/requester";
import Inventory from "./inventory";

const InvoiceItemAdd = (props) => {
  const [data, setData] = useState("");
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [invoicePassengers, setInvoicePassengers] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [gstType, setGST] = useState(props?.gstType || "IGST");
  const [business, setBusiness] = useState(props?.business);
  const [isAdvancedPricing, setAdvancedPricing] = useState(false);
  const [details, setDetails] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [passengerKey, setPassengerKey] = useState(1);
  const [isRoundTrip, setRoundTrip] = useState(props?.tripType || false);
  const [visibleInventory, setVisibleInventory] = useState(false);

  const setDefaultData = (data) => {
    form.setFieldsValue({
      itemName: data?.itemName || "",
      itemLocation: data?.itemLocation || "",
      itemAddress: data?.itemAddress || "",
      checkInDate: data?.checkInDate && dayjs(data?.checkInDate),
      checkOutDate: data?.checkOutDate && dayjs(data?.checkOutDate),
      noOfNights: data?.noOfNights || 0,
      noOfRooms: data?.noOfRooms || 0,
      roomType: data?.roomType || "",
      mealType: data?.mealType || "",
      starRating: data?.starRating || 0,
      sellPrice: data?.sellPrice || 0,
      costPrice: data?.costPrice || 0,
      supplier: data?.supplier || "",
      supplierCurrency: data?.supplierCurrency || "",
      conversionRate: data?.conversionRate || "",
      supplierCostPrice: data?.supplierCostPrice || 0,
      supplierTax: data?.supplierTax || 0,
      markup: data?.markup || 0,
      discount: data?.discount || 0,
      gstType: data?.gstType || "",
      gstPer: data?.gstPer || 0,
      igst: data?.igst || 0,
      cgst: data?.cgst || 0,
      sgst: data?.sgst || 0,
      confirmationNumber: data?.confirmationNumber || "",
      bookBefore: data?.bookBefore && dayjs(data?.bookBefore),
      itemDescription: data?.itemDescription || "",
      isSaveToLibrary: data?.isSaveToLibrary || true,
      duration: data?.duration || "",
      itemType: data?.itemType || "",
      noOfGuests: data?.noOfGuests || 0,

      tripType: data?.tripType || "",
      fromLocation: data?.fromLocation || "",
      toLocation: data?.toLocation || "",
      fromLocationCode: data?.fromLocationCode || "",
      toLocationCode: data?.toLocationCode || "",
      departFromDate: data?.departFromDate && dayjs(data?.departFromDate),
      departFromTime: data?.departFromTime || "",
      departToDate: data?.departToDate && dayjs(data?.departToDate),
      departToTime: data?.departToTime || "",
      departDuration: data?.departDuration || "",
      departAirline: data?.departAirline || "",
      departFlightNumber: data?.departFlightNumber || "",
      departClass: data?.departClass || "",
      departStops: data?.departStops || "",
      departStopsDetails: data?.departStopsDetails || "",
      returnFromDate: data?.returnFromDate && dayjs(data?.returnFromDate),
      returnFromTime: data?.returnFromTime || "",
      returnToDate: data?.returnToDate && dayjs(data?.returnToDate),
      returnToTime: data?.returnToTime || "",
      returnDuration: data?.returnDuration || "",
      returnAirline: data?.returnAirline || "",
      returnFlightNumber: data?.returnFlightNumber || "",
      returnClass: data?.returnClass || "",
      returnStops: data?.returnStops || "",
      returnStopsDetails: data?.returnStopsDetails || "",
      adults: data?.adults || 0,
      child: data?.child || 0,
      infant: data?.infant || 0,

      pickupLocation: data?.pickupLocation || "",
      dropoffLocation: data?.dropoffLocation || "",
      startTime: data?.startTime && dayjs(data?.startTime),
      endTime: data?.endTime && dayjs(data?.endTime),

      inventoryItemId: data?.inventoryItemId || 0,
    });

    setInvoicePassengers(data?.invoicePassengers || []);
  };

  const onFinish = (values) => {
    let item = values;

    item.bookBefore = ResDate(values?.bookBefore);

    if (item?.business !== "Flight") {
      item.checkInDate = ResDate(values?.checkInDate);
      if (item?.business === "Hotel") {
        item.checkOutDate = ResDate(values?.checkOutDate);
      }
      if (item?.business === "Transfer") {
        item.startTime = ResDate(values?.startTime);
        item.endTime = ResDate(values?.endTime);
      }
    } else {
      item.departFromDate = ResDate(values?.departFromDate);
      item.departToDate = ResDate(values?.departToDate);
      item.returnFromDate = ResDate(values?.returnFromDate);
      item.returnToDate = ResDate(values?.returnToDate);
    }

    item.invoicePassengers = invoicePassengers;

    item.basePrice = (values?.costPrice || 0) + (values?.markup || 0);
    item.totalPrice = values?.sellPrice;

    if (props?.isEditMode) {
      item.guId = props?.guId;
      item.invoiceItemId = props?.invoiceItemId;
      props.handleUpdateItem(item);
    } else {
      item.guId = generateGUID();
      props.handleAddItem(item);
    }

    addToInventory(item);
  };

  const handleValuesChange = (value, values) => {
    if (value["checkInDate"]) {
      form.setFieldsValue({
        checkOutDate: dayjs(value["checkInDate"]).add(1, "day"),
      });
    }

    if (business === "Hotel") {
      if (value["checkInDate"] || value["checkOutDate"]) {
        form.setFieldsValue({
          noOfNights: values["checkOutDate"].diff(values["checkInDate"], "day"),
        });
      }
    }

    if (value["tripType"]) {
      setRoundTrip(true);
    } else {
      setRoundTrip(values["tripType"]);
    }

    if (value["departFromDate"]) {
      form.setFieldsValue({
        departFromTime: value["departFromDate"].format("HH:mm"),
      });
    }
    if (value["departToDate"]) {
      form.setFieldsValue({
        departToTime: value["departToDate"].format("HH:mm"),
      });
    }
    if (value["returnFromDate"]) {
      form.setFieldsValue({
        returnFromTime: value["returnFromDate"].format("HH:mm"),
      });
    }
    if (value["returnToDate"]) {
      form.setFieldsValue({
        returnToTime: value["returnToDate"].format("HH:mm"),
      });
    }

    if (value["gstType"]) {
      setGST(value["gstType"]);
    }

    CalcPrice(value, values);
  };

  const CalcPrice = (value, values) => {
    let sellPrice = 0;
    let costPrice = 0;
    let gstPer = values["gstPer"] || 18;
    let igst = 0;
    let sgst = 0;
    let cgst = 0;

    costPrice =
      (values["supplierCostPrice"] || 0) + (values["supplierTax"] || 0);

    let taxable =
      Number(values["supplierCostPrice"] || 0) +
      Number(values["supplierTax"] || 0) +
      Number(values["markup"] || 0) -
      Number(values["discount"] || 0);

    console.log(value["igst"], value["sgst"], value["cgst"]);

    if (values["gstType"] === undefined) {
      sellPrice = taxable;
      gstPer = 0;
      igst = 0;
      sgst = 0;
      cgst = 0;
      form.setFieldsValue({
        gstPer: 0,
        igst: 0,
        sgst: 0,
        cgst: 0,
      });
    } else {
      if (values["gstType"] === "IGST" && !value["igst"]) {
        igst = (taxable * gstPer) / 100;
        form.setFieldsValue({ gstPer: gstPer, igst: igst });
      }

      if (
        values["gstType"] === "CGST/SGST" &&
        !value["sgst"] &&
        !value["cgst"]
      ) {
        sgst = (taxable * gstPer) / 100 / 2;
        cgst = (taxable * gstPer) / 100 / 2;
        form.setFieldsValue({
          gstPer: gstPer,
          sgst: sgst,
          cgst: cgst,
        });
      }

      sellPrice = taxable + igst + sgst + cgst;
    }

    if (value["sellPrice"]) {
      form.setFieldsValue({ sellPrice: values["sellPrice"] });
      form.setFieldsValue({ costPrice: values["sellPrice"] });
    } else {
      if (
        value["supplierCostPrice"] ||
        value["supplierCostPrice"] === 0 ||
        value["supplierTax"] ||
        value["supplierTax"] === 0 ||
        value["markup"] ||
        value["markup"] === 0 ||
        value["gstPer"] ||
        value["gstPer"] === 0 ||
        value["igst"] ||
        value["igst"] === 0 ||
        value["cgst"] ||
        value["cgst"] === 0 ||
        value["sgst"] ||
        value["sgst"] === 0 ||
        value["discount"] ||
        value["discount"] === 0 ||
        value["gstType"]
      ) {
        setDisabled(true);
        form.setFieldsValue({ costPrice: costPrice });
        form.setFieldsValue({ sellPrice: sellPrice });
      }
    }
  };

  const showDrawer = () => {
    setIsEditMode(false);
    setDetails("");
    setPassengerKey(passengerKey + 1);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleAddPassenger = (passenger) => {
    invoicePassengers.push(passenger);
    setInvoicePassengers(invoicePassengers);
    setPassengerKey(passengerKey + 1);
    onClose();
  };

  const handleEditPassenger = (passenger) => {
    setDetails(passenger);
    setIsEditMode(true);
    setPassengerKey(passengerKey + 1);
    setVisible(true);
  };

  const handleDeletePassenger = (passenger) => {
    let invoiceNewPassenger = invoicePassengers.filter(
      (x) =>
        x?.guId !== passenger?.guId ||
        x?.invoicePassengerId !== passenger?.invoicePassengerId
    );
    setInvoicePassengers(invoiceNewPassenger);
  };

  const handleUpdatePassenger = (passenger) => {
    let updatedInvoicePassengers = invoicePassengers.map((x) =>
      x?.guId === passenger?.guId &&
      x?.invoicePassengerId === passenger?.invoicePassengerId
        ? passenger
        : x
    );
    setPassengerKey(passengerKey + 1);
    setInvoicePassengers(updatedInvoicePassengers);
    onClose();
  };

  const generateGUID = () => {
    const s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  };

  const handleAdvancedPricing = () => {
    setAdvancedPricing(!isAdvancedPricing);
  };

  const showInventoryDrawer = () => {
    setVisibleInventory(true);
  };

  const onCloseInventory = () => {
    setVisibleInventory(false);
  };

  const handleInventoryItem = (item) => {
    setDefaultData(item);
    onCloseInventory();
  };

  const addToInventory = (item) => {
    let reqURL = "inventory/" + (props?.isEditMode ? "update" : "add");
    let reqOBJ = item;

    if (item?.inventoryItemId) {
      reqOBJ.inventoryItemId = item?.inventoryItemId;
    }

    if (props?.isEditMode && props?.from === "inventory") {
      fetchData(reqURL, reqOBJ, (data) => {
        setLoading(false);
        props?.from && props.handleAddInventoryItem(item);
      });
    }

    if (!item?.inventoryItemId && !props?.isEditMode) {
      fetchData(reqURL, reqOBJ, (data) => {
        setLoading(false);
        props?.from && props.handleAddInventoryItem(item);
      });
    }
  };

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const col1 = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 19,
    },
  };

  const passengerColumns = [
    {
      title: "Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button
            className="reset-icon-btn"
            icon={<EditOutlined />}
            onClick={() => handleEditPassenger(record)}
          />
          <Button
            className="reset-icon-btn"
            icon={<DeleteOutlined />}
            onClick={() => handleDeletePassenger(record)}
          />
        </div>
      ),
      width: 50,
    },
  ];

  useEffect(() => {
    props?.isEditMode && setDefaultData(props);
  }, []);

  return (
    <React.Fragment>
      <Button
        style={{ position: "absolute", right: "16px", top: "12px" }}
        type="primary"
        icon={<PlusOutlined />}
        onClick={showInventoryDrawer}
      >
        Add from Inventory
      </Button>
      <Form
        name="invoice-item-add"
        form={form}
        {...layout}
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={handleValuesChange}
        initialValues={{
          business: business,
          checkInDate: dayjs(),
          checkOutDate: dayjs().add(1, "day"),
          bookBefore: dayjs().add(-1, "day"),
          noOfRooms: 1,
          isSaveToLibrary: true,
          supplierCurrency: "INR",
        }}
      >
        <Form.Item label="Business" name="business" {...col1} hidden>
          <Input placeholder="Business" />
        </Form.Item>

        {business === "Package" && (
          <React.Fragment>
            <Row>
              <Col xs={24} xl={24}>
                <Form.Item label="Package Name" name="itemName" {...col1}>
                  <Input placeholder="Package Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Destinations" name="itemLocation">
                  <Input placeholder="Destinations" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Country" name="itemAddress">
                  <Input placeholder="Country" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {props?.from !== "inventory" && (
                <Col xs={24} xl={12}>
                  <Form.Item label="Package Date" name="checkInDate">
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      className="in-100"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} xl={12}>
                <Form.Item label="Duration" name="duration">
                  <Input placeholder="Duration - 3 Days" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Package Type" name="itemType">
                  <Input placeholder="Package Type" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="No of Guest" name="noOfGuests">
                  <InputNumber placeholder="No of Guest" className="in-100" />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {business === "Hotel" && (
          <React.Fragment>
            <Row>
              <Col xs={24} xl={24}>
                <Form.Item label="Hotel Name" name="itemName" {...col1}>
                  <Input placeholder="Hotel Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Location" name="itemLocation">
                  <Input placeholder="Location" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Address" name="itemAddress">
                  <Input placeholder="Address" />
                </Form.Item>
              </Col>
            </Row>
            {props?.from !== "inventory" && (
              <Row>
                <Col xs={24} xl={12}>
                  <Form.Item label="Check In" name="checkInDate">
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      className="in-100"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12}>
                  <Form.Item label="Check Out" name="checkOutDate">
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      className="in-100"
                      allowClear={false}
                    />
                  </Form.Item>
                  <Form.Item name="noOfNights" hidden>
                    <Input placeholder="Night(s)" className="in-100" />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="No of Rooms" name="noOfRooms">
                  <InputNumber placeholder="No of Rooms" className="in-100" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Room Types" name="roomType">
                  <Input placeholder="Room Types" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Meal Type" name="mealType">
                  <Input placeholder="Meal Type" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Star Rating" name="starRating">
                  <InputNumber placeholder="Star Rating" className="in-100" />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {business === "Activity" && (
          <React.Fragment>
            <Row>
              <Col xs={24} xl={24}>
                <Form.Item label="Activity Name" name="itemName" {...col1}>
                  <Input placeholder="Activity Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Location" name="itemLocation">
                  <Input placeholder="Location" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Address" name="itemAddress">
                  <Input placeholder="Address" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {props?.from !== "inventory" && (
                <Col xs={24} xl={12}>
                  <Form.Item label="Activity Date" name="checkInDate">
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      className="in-100"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} xl={12}>
                <Form.Item label="Duration" name="duration">
                  <Input placeholder="Activity Duration" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Activity Type" name="itemType">
                  <Input placeholder="Activity Type" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="No of Guest" name="noOfGuests">
                  <InputNumber placeholder="No of Guest" className="in-100" />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {business === "Other" && (
          <React.Fragment>
            <Row>
              <Col xs={24} xl={24}>
                <Form.Item label="Item Name" name="itemName" {...col1}>
                  <Input placeholder="Item Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Location" name="itemLocation">
                  <Input placeholder="Location" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Address" name="itemAddress">
                  <Input placeholder="Address" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Item Type" name="itemType">
                  <Input placeholder="Item Type" />
                </Form.Item>
              </Col>
              {props?.from !== "inventory" && (
                <Col xs={24} xl={12}>
                  <Form.Item label="Item Date" name="checkInDate">
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      className="in-100"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </React.Fragment>
        )}

        {business === "Flight" && (
          <React.Fragment>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="From City" name="fromLocation">
                  <Input placeholder="From City" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="To City" name="toLocation">
                  <Input placeholder="To City" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="From Airport" name="fromLocationCode">
                  <Input placeholder="From Airport Code" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="To Airport" name="toLocationCode">
                  <Input placeholder="To Airport Code" />
                </Form.Item>
              </Col>
            </Row>

            <React.Fragment>
              <Row>
                <Col xs={24} xl={12}>
                  <Form.Item label="Depart Date/Time" name="departFromDate">
                    <DatePicker
                      format={"DD/MM/YYYY HH:mm"}
                      showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
                      className="in-100"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12}>
                  <Form.Item label="Arrive Date/Time" name="departToDate">
                    <DatePicker
                      format={"DD/MM/YYYY HH:mm"}
                      showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
                      className="in-100"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} xl={12}>
                  <Form.Item label="Depart Time" name="departFromTime" hidden>
                    <Input placeholder="Depart Time" />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12}>
                  <Form.Item label="Arrive Time" name="departToTime" hidden>
                    <Input placeholder="Depart Time" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} xl={12}>
                  <Form.Item label="Duration" name="departDuration">
                    <Input placeholder="Duration" />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12}>
                  <Form.Item label="Airline" name="departAirline">
                    <Input placeholder="Airline" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} xl={12}>
                  <Form.Item label="Flight Number" name="departFlightNumber">
                    <Input placeholder="Flight Number" />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12}>
                  <Form.Item label="Class" name="departClass">
                    <Input placeholder="Class" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} xl={12}>
                  <Form.Item label="Stops" name="departStops">
                    <Input placeholder="Stops" />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12}>
                  <Form.Item label="Stops Details" name="Stops Details">
                    <Input placeholder="Stops Details" />
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>

            <Row>
              <Col xs={24} xl={12}>
                <Row>
                  <Col xs={24} xl={14}>
                    <Form.Item
                      label="Pax"
                      name="adults"
                      labelCol={{
                        span: 17,
                      }}
                    >
                      <InputNumber
                        placeholder="Adult"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} xl={5}>
                    <Form.Item
                      name="child"
                      wrapperCol={{
                        span: 24,
                      }}
                      style={{ marginLeft: "8px" }}
                    >
                      <InputNumber
                        placeholder="Child"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={5}>
                    <Form.Item
                      name="infant"
                      wrapperCol={{
                        span: 24,
                      }}
                      style={{ marginLeft: "8px" }}
                    >
                      <InputNumber
                        placeholder="Infant"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Form.Item
              name="tripType"
              valuePropName="checked"
              wrapperCol={{ offset: 5, span: 15 }}
            >
              <Checkbox>Round Trip</Checkbox>
            </Form.Item>

            {isRoundTrip && (
              <React.Fragment>
                <Row>
                  <Col xs={24} xl={12}>
                    <Form.Item label="Depart Date/Time" name="returnFromDate">
                      <DatePicker
                        format={"DD/MM/YYYY HH:mm"}
                        showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
                        className="in-100"
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item label="Arrive Date/Time" name="returnToDate">
                      <DatePicker
                        format={"DD/MM/YYYY HH:mm"}
                        showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
                        className="in-100"
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} xl={12}>
                    <Form.Item label="Depart Time" name="returnFromTime" hidden>
                      <Input placeholder="Return From Time" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item label="Arrive Time" name="returnToTime" hidden>
                      <Input placeholder="Return To Time" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} xl={12}>
                    <Form.Item label="Duration" name="returnDuration">
                      <Input placeholder="Duration" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item label="Airline" name="returnAirline">
                      <Input placeholder="Airline" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} xl={12}>
                    <Form.Item label="Flight Number" name="returnFlightNumber">
                      <Input placeholder="Flight Number" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item label="Class" name="returnClass">
                      <Input placeholder="Class" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} xl={12}>
                    <Form.Item label="Stops" name="returnStops">
                      <Input placeholder="Stops" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item label="Stops Details" name="returnStopsDetails">
                      <Input placeholder="Stops Details" />
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {business === "Transfer" && (
          <React.Fragment>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Pickup Location" name="pickupLocation">
                  <Input placeholder="Pickup Location" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Dropoff Location" name="dropoffLocation">
                  <Input placeholder="Dropoff Location" />
                </Form.Item>
              </Col>
            </Row>

            {props?.from !== "inventory" && (
              <Row>
                <Col xs={24} xl={12}>
                  <Form.Item label="Pickup Date/Time" name="startTime">
                    <DatePicker
                      format={"DD/MM/YYYY HH:mm"}
                      showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
                      className="in-100"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12}>
                  <Form.Item label="Dropoff Date/Time" name="endTime">
                    <DatePicker
                      format={"DD/MM/YYYY HH:mm"}
                      showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
                      className="in-100"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Vehicle Detail" name="itemType">
                  <Input placeholder="Vehicle Detail" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Duration" name="duration">
                  <Input placeholder="Duration" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="No of Guests" name="noOfGuests">
                  <InputNumber placeholder="No of Guest" className="in-100" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Trip Detail" name="tripType">
                  <Input placeholder="Trip Detail" />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        )}

        <Row>
          <Col xs={24} xl={12}>
            <Form.Item label="Sell Price" name="sellPrice">
              <InputNumber
                placeholder="Sell Price"
                className="in-100"
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item label="Cost Price" name="costPrice">
              <InputNumber
                placeholder="Cost Price"
                className="in-100"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} xl={24}>
            <Form.Item label="Description" name="itemDescription" {...col1}>
              <Input.TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
        {isAdvancedPricing && (
          <React.Fragment>
            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Supplier" name="supplier">
                  <Input placeholder="Supplier" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Currency" name="supplierCurrency">
                  <Input placeholder="Currency" />
                </Form.Item>
                <Form.Item label="Conversion Rate" name="conversionRate" hidden>
                  <InputNumber
                    placeholder="Conversion Rate"
                    className="in-100"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Supplier Price" name="supplierCostPrice">
                  <InputNumber
                    placeholder="Supplier Price"
                    className="in-100"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Supplier Tax" name="supplierTax">
                  <InputNumber placeholder="Supplier Tax" className="in-100" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="Markup" name="markup">
                  <InputNumber placeholder="Markup" className="in-100" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item label="Discount" name="discount">
                  <InputNumber placeholder="Discount" className="in-100" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} xl={12}>
                <Form.Item label="GST" name="gstPer">
                  <InputNumber
                    addonBefore={
                      <Form.Item name="gstType" noStyle>
                        <Select
                          placeholder="GST Type"
                          options={gstTypeList}
                          allowClear
                          style={{ width: 118, textAlign: "left" }}
                        ></Select>
                      </Form.Item>
                    }
                    placeholder="GST %"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} xl={12}>
                {gstType === "IGST" && (
                  <Form.Item label="IGST" name="igst">
                    <InputNumber placeholder="IGST" className="in-100" />
                  </Form.Item>
                )}

                {gstType === "CGST/SGST" && (
                  <Row>
                    <Col xs={24} xl={16}>
                      <Form.Item
                        label="CGST/SGST"
                        name="cgst"
                        labelCol={{
                          span: 15,
                        }}
                      >
                        <InputNumber
                          placeholder="CGST"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} xl={8}>
                      <Form.Item
                        name="sgst"
                        wrapperCol={{
                          span: 24,
                        }}
                        style={{ marginLeft: "16px" }}
                      >
                        <InputNumber
                          placeholder="SGST"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            {props?.from !== "inventory" && (
              <Row>
                <Col xs={24} xl={12}>
                  <Form.Item label="Confirmation" name="confirmationNumber">
                    <Input placeholder="Confirmation Number" />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12}>
                  <Form.Item label="Book Before" name="bookBefore">
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      className="in-100"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}

        <Form.Item
          wrapperCol={{
            offset: 5,
          }}
          style={{ marginTop: "-20px" }}
        >
          <Button
            type="link"
            onClick={handleAdvancedPricing}
            style={{
              padding: "0px",
              margin: "0px",
            }}
          >
            {!isAdvancedPricing ? "Show" : "Hide"} Advanced Pricing
          </Button>
        </Form.Item>

        <Form.Item label="Guests" {...col1}>
          <Button onClick={showDrawer} icon={<PlusOutlined />}>
            Add Guest
          </Button>

          {invoicePassengers && invoicePassengers.length > 0 && (
            <Table
              columns={passengerColumns}
              rowKey={(record) => record?.guId || record?.invoicePassengerId}
              dataSource={invoicePassengers || []}
              size="small"
              key={passengerKey}
              pagination={false}
              style={{ marginTop: "16px" }}
              bordered
            />
          )}
        </Form.Item>

        <Form.Item
          name="isSaveToLibrary"
          valuePropName="checked"
          wrapperCol={{ offset: 5, span: 15 }}
          hidden
        >
          <Checkbox>Save to Library</Checkbox>
        </Form.Item>

        <Form.Item name="inventoryItemId" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 5,
            span: 19,
          }}
          className="btn-row"
        >
          <Button
            icon={!props?.isEditMode ? <PlusOutlined /> : <EditOutlined />}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {!props?.isEditMode ? "Add " + business : "Update " + business}
          </Button>
        </Form.Item>
      </Form>

      <Drawer
        title={"Add Guest"}
        placement="right"
        onClose={onClose}
        open={visible}
        size={"large"}
      >
        <InvoicePassengerAdd
          isEditMode={isEditMode}
          {...details}
          key={passengerKey}
          handleAddPassenger={handleAddPassenger}
          handleUpdatePassenger={handleUpdatePassenger}
        />
      </Drawer>

      <Drawer
        title={"Inventory"}
        placement="right"
        onClose={onCloseInventory}
        open={visibleInventory}
        size={"large"}
        width={800}
      >
        <Inventory
          business={business}
          handleInventoryItem={handleInventoryItem}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default InvoiceItemAdd;
