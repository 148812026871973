import React, { useEffect, useState } from "react";
import fetchData from "../services/requester";
import { Table, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Date, Currency, Time, DateTime } from "../helpers/formatter";

const Inventory = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState(props?.business || "");

  const getData = () => {
    let reqURL = "inventory";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (res) => {
        let data = res?.filter((x) => x?.business === business) || [];
        setData(data || []);
        setLoading(false);
      },
      "GET"
    );
  };

  const handleInventoryItem = (record) => {
    props.handleInventoryItem(record);
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "business",
      key: "business",
      width: 74,
    },
    {
      title: "Details",
      dataIndex: "itemName",
      key: "itemName",
      render: (text, record) => (
        <React.Fragment>
          <Row>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              <div>
                <div>{record?.itemName}</div>
              </div>
            </Col>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              {record?.business === "Hotel" && (
                <div>
                  <div>
                    {record?.itemLocation} - {record?.roomType}
                  </div>
                </div>
              )}
              {(record?.business === "Package" ||
                record?.business === "Activity" ||
                record?.business === "Other") && (
                <div>
                  <div>
                    {record?.itemType}

                    {record?.business === "Package" && " - " + record?.duration}
                  </div>
                </div>
              )}
            </Col>
          </Row>

          {record?.business === "Flight" && (
            <Row>
              <Col span={12}>
                <div style={{ fontSize: "0.8rem" }}>
                  <div>
                    {record?.fromLocation} ({record?.fromLocationCode}){" - "}
                    {record?.toLocation} ({record?.toLocationCode})
                  </div>

                  {record?.tripType ? (
                    <React.Fragment>
                      <div className="mt-2">
                        {record?.toLocation} ({record?.toLocationCode}){" - "}
                        {record?.fromLocation} ({record?.fromLocationCode})
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </Col>
              <Col span={12}>
                <div style={{ fontSize: "0.8rem" }}>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      marginTop: "2px",
                      opacity: "0.6",
                    }}
                  >
                    {record?.departAirline}
                    {" | "}
                    {record?.departDuration}
                    {" | "}
                    {record?.departClass}
                  </div>

                  {record?.tripType ? (
                    <React.Fragment>
                      <div
                        style={{
                          fontSize: "0.8rem",
                          marginTop: "2px",
                          opacity: "0.6",
                        }}
                      >
                        {record?.returnAirline}
                        {" | "}
                        {record?.returnDuration}
                        {" | "}
                        {record?.departClass}
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </Col>
            </Row>
          )}

          {record?.business === "Transfer" && (
            <Row>
              <Col span={24}>
                <div>
                  <div>
                    {record?.pickupLocation} - {record?.dropoffLocation}
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      marginTop: "2px",
                      opacity: "0.6",
                    }}
                  >
                    {DateTime(record?.startTime)} - {Time(record?.endTime)}
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      marginTop: "2px",
                      opacity: "0.6",
                    }}
                  >
                    {record?.itemType} {record?.tripType}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </React.Fragment>
      ),
    },
    {
      title: "Price",
      dataIndex: "sellPrice",
      key: "sellPrice",
      render: (text) => Currency(text || 0),
      width: 100,
    },
    {
      title: "Add",
      render: (text, record) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button
            type="primary"
            size="small"
            icon={<PlusOutlined />}
            onClick={() => handleInventoryItem(record)}
          />
        </div>
      ),
      width: 50,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Table
        columns={columns}
        rowKey={(record) => record?.inventoryItemId}
        dataSource={data || []}
        loading={loading}
        size="small"
        pagination={false}
      />
    </div>
  );
};

export default Inventory;
