import config from "../config.json";

const appPath = config?.mode === "dev" ? config.devPath : config.livePath;

const fetchData = (reqURL, reqOBJ, callback, reqMethod) => {
  return fetch(appPath + reqURL, {
    method: reqMethod || "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("website-token"),
    },
    body: reqOBJ ? JSON.stringify(reqOBJ) : null,
  })
    .then((res) => res?.text())
    .then((data) => {
      if (!data || data === "") {
        callback({ success: true });
      } else {
        try {
          callback(JSON.parse(data));
        } catch (e) {
          callback(data);
        }
      }
    })
    .catch((e) => callback(e));
};

export default fetchData;
