import React from "react";
import { Link } from "react-router-dom";
import { Tabs } from "antd";
import ManagePages from "./manage-pages";
import ManageDeals from "./manage-deals";
import ManageDestinations from "./manage-destinations";
import ManageTestimonials from "./manage-testimonials";
import ManagePromotions from "./manage-promotions";
import ManageWhyus from "./manage-whyus";
import ManageSettings from "./manage-settings";

const ManageWebsite = (props) => {
  const items = [
    {
      key: "1",
      label: "Pages",
      children: <ManagePages />,
    },
    {
      key: "2",
      label: "Banner Promotions",
      children: <ManagePromotions />,
    },
    {
      key: "3",
      label: "Deals",
      children: <ManageDeals />,
    },
    {
      key: "4",
      label: "Destinations",
      children: <ManageDestinations />,
    },
    {
      key: "5",
      label: "Testimonials",
      children: <ManageTestimonials />,
    },
    {
      key: "6",
      label: "Whyus",
      children: <ManageWhyus />,
    },
    {
      key: "7",
      label: "Website Settings",
      children: <ManageSettings />,
    },
  ];

  return (
    <div className="website-admin">
      <div>
        <Tabs
          defaultActiveKey="1"
          items={items}
          tabBarExtraContent={
            <Link
              key={1}
              type="primary"
              to={"/website/" + props?.url}
              target="_blank"
            >
              Visit Website
            </Link>
          }
        />
      </div>
    </div>
  );
};

export default ManageWebsite;
