import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import fetchData from "./services/requester-website";
import "./assets/css/style-website.css";
import WebsiteHeader from "./components/website/website-header";
import WebsiteFooter from "./components/website/website-footer";
import WebHome from "./screens/website/web-home";
import WebPage from "./screens/website/web-page";
import Deals from "./screens/website/deals";
import DealDetails from "./screens/website/deal-details";
import DestinationDetails from "./screens/website/destination-details";
import QuickProposalDetails from "./screens/website/quick-proposal-details";
import WebLoader from "./components/website/web-loader";
import PromotionDetails from "./screens/website/promotion-details";
import InvoiceDetails from "./screens/website/invoice-details";

function AppWebsite() {
  const [portal, setPortal] = useState("");
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const site = location.pathname.split("/")[2];

  const auth = () => {
    let reqURL = "auth?url=" + site;
    let reqOBJ = "";

    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          localStorage.setItem("website-token", data);
          getPortal();
        }
      },
      "GET"
    );
  };

  const getPortal = () => {
    let reqURL = "portal-settings";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setPortal(data[0] || []);
        localStorage.setItem("portalId", data[0]?.portalId);
        setLoading(false);
      },
      "GET"
    );
  };

  useEffect(() => {
    auth();
  }, []);

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          <WebsiteHeader {...portal} setMenu={setMenu} />
          <div
            className="web-page-height"
            style={{ minHeight: "calc(100vh - 158px)" }}
          >
            <Routes>
              <Route
                key={101}
                path={"/website/" + site}
                element={<WebHome {...portal} />}
              />
              <Route
                key={102}
                path={"/website/" + site + "/:page"}
                element={
                  <WebPage
                    menu={menu}
                    key={location?.pathname + menu?.length}
                  />
                }
              />
              <Route
                key={103}
                path={"/website/" + site + "/deals"}
                element={<Deals {...portal} />}
              />
              <Route
                key={104}
                path={"/website/" + site + "/deals/:id"}
                element={<DealDetails {...portal} />}
              />
              <Route
                key={105}
                path={"/website/" + site + "/destination/:id"}
                element={<DestinationDetails {...portal} />}
              />
              <Route
                key={106}
                path={"/website/" + site + "/promotions/:id"}
                element={<PromotionDetails {...portal} />}
              />
              <Route
                key={107}
                path={"/website/" + site + "/quick-proposal-details/:id"}
                element={<QuickProposalDetails {...portal} />}
              />
              <Route
                key={108}
                path={"/website/" + site + "/invoice-details/:id"}
                element={<InvoiceDetails {...portal} />}
              />
              <Route
                key={109}
                path={"/website/" + site + "/voucher-details/:id"}
                element={<InvoiceDetails {...portal} />}
              />
            </Routes>
          </div>
          <WebsiteFooter {...portal} />
        </React.Fragment>
      )}
      {loading && <WebLoader />}
    </React.Fragment>
  );
}

export default AppWebsite;
