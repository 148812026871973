import React, { useEffect, useState } from "react";
import { Button, Select, Input, Drawer, message, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";
import CustomerQuickAdd from "./customer-quick-add";

const CustomersDropDown = (props) => {
  const [customers, setCustomers] = useState();
  const [visible, setVisible] = useState(false);
  const [customer, setCustomer] = useState();

  const getCustomers = () => {
    let reqURL = "customers";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        let customers = data || [];
        setCustomers(customers);
      },
      "GET"
    );
  };

  const handleChange = (value) => {
    let customer = value && value.split("|")[0];
    props.handleCustomer(customer);
  };

  const handleSelect = (value) => {
    let customer = value && value.split("|")[0];
    props.handleCustomer(customer);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleAdd = () => {
    setVisible(true);
  };

  const addSuccess = (customer) => {
    setVisible(false);
    let newCustomers = [...customers];
    newCustomers.push({
      customerId: customer.customerId,
      name: customer.name,
      email: customer.email,
      countryCode: customer.countryCode,
      phone: customer.phone,
    });
    setCustomers(newCustomers);
    setCustomer(
      customer.customerId +
        "|" +
        customer.name +
        "|" +
        customer.email +
        "|" +
        customer.countryCode +
        "|" +
        customer.phone
    );
    message.success("Customer added successfully");
    props.handleCustomer(customer.customerId);
  };

  const getValue = (customer) => {
    return (
      customer.customerId +
      "|" +
      customer?.name +
      "|" +
      customer?.email +
      "|" +
      customer?.countryCode +
      "|" +
      customer?.phone
    );
  };

  const setData = () => {
    setCustomer(
      props?.customerId &&
        props?.customerId +
          "|" +
          props.name +
          "|" +
          props.email +
          "|" +
          props.countryCode +
          "|" +
          props.phone
    );
  };

  useEffect(() => {
    getCustomers();
    props?.isEditMode && setData();
  }, []);

  return (
    <React.Fragment>
      <Space.Compact key={customer} style={{ width: "100%" }}>
        <Select
          placeholder="Select Existing Customer"
          onChange={handleChange}
          onSelect={handleSelect}
          showSearch
          allowClear
          defaultValue={customer}
          style={{ width: "84%" }}
          key={customer}
        >
          {customers &&
            customers.map((x) => (
              <Select.Option value={getValue(x)} key={x.customerId}>
                {x.name}
                <span
                  style={{ float: "right", fontSize: "13px", color: "#999" }}
                >
                  {x?.email} {x?.countryCode + x?.phone}
                </span>
              </Select.Option>
            ))}
        </Select>
        <Button
          icon={<PlusOutlined />}
          style={{ width: "16%" }}
          type="primary"
          onClick={handleAdd}
        >
          New
        </Button>
      </Space.Compact>

      <Drawer
        title="Add Customer"
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <CustomerQuickAdd
          handleAdd={addSuccess}
          customerType={props?.customerType}
          key={visible}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default CustomersDropDown;
