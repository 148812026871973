import React from "react";
import { Layout, Typography } from "antd";
import Logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";

const AppHeaderPublic = (props) => {
  const { Header } = Layout;
  const { Title } = Typography;

  return (
    <Header className="public-header">
      <div className="container" style={{ position: "relative" }}>
        <Link className="logo" to="/">
          <img src={Logo} alt="BizVeda" />
        </Link>
        <div className="public-header-menu">
          <Link to="/about-us">About Us</Link>
          <Link to="/signin">Sign in</Link>
          <Link to="/signup">Sign up</Link>
        </div>
      </div>
    </Header>
  );
};

export default AppHeaderPublic;
