import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Typography, Avatar, Button } from "antd";
import Logo from "../assets/images/logo.png";
import fetchData from "../services/requester";

const AppHeader = (props) => {
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const { Header } = Layout;
  const { Title } = Typography;

  const getData = () => {
    let reqURL = "portals/detail";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        data && setData(data[0] || []);
        data && props.setPortal(data[0] || "");
        data && localStorage.setItem("portalId", data[0]?.portalId);
      },
      "GET"
    );
  };

  const handleLogout = () => {
    localStorage.removeItem("admin-token");
    navigate("/");
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Header style={{ paddingLeft: "20px" }}>
      <Title level={3} className="logo">
        <img src={Logo} alt="BizVeda" />
      </Title>

      <div style={{ position: "absolute", top: "0px", right: "28px" }}>
        <Avatar
          size={36}
          shape="round"
          style={{
            marginRight: "12px",
            background: "#e9f3fc",
            color: "#0d2943",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              textTransform: "uppercase",
              lineHeight: "18px",
            }}
          >
            {data?.name?.charAt(0)}
          </span>
        </Avatar>
        <span>{data?.name}</span>
        <Button type="link" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </Header>
  );
};

export default AppHeader;
