import React from "react";
import TargetIcon1 from "../assets/images/public/target-icon-1.png";
import TargetIcon2 from "../assets/images/public/target-icon-2.png";
import TargetIcon3 from "../assets/images/public/target-icon-3.png";
import TargetIcon4 from "../assets/images/public/target-icon-4.png";

const AboutUs = () => {
  return (
    <div>
      <div>
        <div className="container">
          <h1 className="mt-4 mb-5" style={{ fontWeight: "600" }}>
            About US
          </h1>
        </div>
      </div>
      <div className="container">
        <div style={{ fontSize: "1.2rem" }}>
          <p>
            BizVeda, founded by IT industry veterans with over 50 years of
            combined experience, fills a crucial niche in online solutions for
            small businesses. While many platforms cater to larger enterprises,
            BizVeda focuses on the unique needs of professionals and small
            companies. Recognizing that smaller businesses require affordable,
            user-friendly tools to establish an online presence and deliver
            prompt service, we created BizVeda.
          </p>

          <p>
            Our platform is tailored for businesses run by one to ten
            individuals, offering essential features such as CRM, product
            promotion, inquiry management, and invoicing. With BizVeda,
            businesses can effectively communicate with customers through
            various channels like email, WhatsApp, and various social media.
          </p>

          <p>
            BizVeda tools help our clients streamline their operations without
            the need for extensive training or high costs. Whether accessed via
            our SaaS platform or installed on the user's server, BizVeda
            provides flexibility and scalability. Additionally, we offer bespoke
            customization services to meet the unique needs of each user.
          </p>
        </div>

        <div className="public-home">
          <div
            className="public-home-banner"
            style={{
              minHeight: "auto",
              marginTop: "62px",
              marginBottom: "62px",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2
                    style={{
                      color: "#333",
                      fontWeight: "600",
                      fontSize: "2.0rem",
                    }}
                  >
                    Take Your Business Online with BizVeda
                  </h2>

                  <h3 style={{ color: "#666", fontSize: "1.4rem" }}>
                    Elevate Your Brand, Delight Clients and Capture New
                    Opportunities!
                  </h3>

                  <ul style={{ color: "#333" }}>
                    <li>
                      Easily reach your customers with the BizVeda CRM module
                      any time from any device
                    </li>
                    <li>
                      Showcase what you sell through BizVeda's simple to use
                      Website builder
                    </li>
                    <li>
                      Promote your products and promotions online using WhatsApp
                      and social media
                    </li>
                    <li>
                      Never lose an Invoice or a payment using our Invoicing
                      platform
                    </li>
                    <li>
                      Worried about your data, no problem!!, BizVeda can be
                      installed on your servers
                    </li>
                    <li>
                      Want to make some changes, no issues, we provide
                      customization services
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="public-home-users"
          style={{ marginTop: "48px", marginBottom: "48px" }}
        >
          <div className="container">
            <h2 style={{ fontWeight: "600", fontSize: "2.0rem" }}>
              Who BizVeda Is For
            </h2>
            <h5 className="mt-3">
              Our solutions are ideal for Travel professionals, startups or
              small business owners
            </h5>
            <div className="row mt-5">
              <div className="col-lg-3">
                <div className="public-home-users-wrap public-home-users-wrap-1">
                  <img src={TargetIcon1} alt="" />
                  <h5>Want to show their products and promotions online</h5>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="public-home-users-wrap public-home-users-wrap-2">
                  <img src={TargetIcon2} alt="" />
                  <h5>Easily market them via WhatsApp and social media</h5>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="public-home-users-wrap public-home-users-wrap-3">
                  <img src={TargetIcon3} alt="" />
                  <h5>Affordability is an important criterion</h5>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="public-home-users-wrap public-home-users-wrap-4">
                  <img src={TargetIcon4} alt="" />
                  <h5>Simple to use and easy to manage</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
