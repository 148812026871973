import React, { useState, useEffect } from "react";
import fetchData from "../../services/requester-website";
import { imagePath } from "../../helpers/global";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  const [data, setData] = useState([]);

  const getData = () => {
    let reqURL = "promotions";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        data && setData(data?.filter((x) => x?.showOnHome) || []);
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="home-banner">
      {data?.map(
        (x, key) =>
          key < 4 && (
            <div className="home-banner-bg" key={key}>
              <img src={imagePath() + x?.imageURL} alt="" />
              <div className="home-banner-content">
                <div className="container">
                  <h2>{x?.title}</h2>
                  <h5>{x?.subTitle}</h5>
                  {x?.isDetail && (
                    <Link
                      className="btn btn-primary home-banner-btn"
                      to={"promotions/" + x?.promotionId}
                    >
                      Know More
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default HomeBanner;
