import React, { useState } from "react";
import { Upload, Button } from "antd";
import fetchFile from "../services/requester-file";
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { imagePath } from "../helpers/global";

const UploadImg = (props) => {
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const uploadImage = (options) => {
    setLoading(true);
    const { onSuccess, onError, file } = options;
    const fmData = new FormData();
    fmData.append("myFile", file, file.name);
    let reqURL = props?.path || "upload-package-image";
    let reqOBJ = fmData;
    fetchFile(reqURL, reqOBJ, (data) => {
      setLoading(false);
      if (data === "upload failed") {
        onError({ data });
      } else {
        onSuccess("Ok");
        props.handleUpload(data);
      }
    });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleOnChange = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };

  const handlePreview = () => {
    window.open(imagePath() + props?.image, "_blank");
  };

  return (
    <React.Fragment>
      <Upload
        accept="image/*"
        customRequest={uploadImage}
        onChange={handleOnChange}
        listType={props?.type || "picture-card"}
        defaultFileList={defaultFileList}
        className="image-upload-grid"
        maxCount={1}
      >
        {props?.type === "file-input" && (
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        )}
        {props?.type === "file-input" || defaultFileList.length >= 1
          ? null
          : uploadButton}
      </Upload>

      {props?.image && defaultFileList.length === 0 && (
        <React.Fragment>
          <Button
            icon={<EyeOutlined />}
            onClick={handlePreview}
            style={{
              padding: "0px",
              margin: "8px 0px 0px 0px",
              border: "0px none",
              boxShadow: "none",
              height: "auto",
            }}
          >
            <span>{props?.image}</span>
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default UploadImg;
