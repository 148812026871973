import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Select, message } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { SettingOutlined, EditOutlined } from "@ant-design/icons";
import { countryList } from "../helpers/lookups";
import fetchData from "../services/requester";
import UploadImg from "../components/upload-img";

const Settings = (props) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const getData = () => {
    let reqURL = "portals/detail";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        data && setData(data[0] || []);
        data && setDefaultData(data[0]);
      },
      "GET"
    );
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      name: data?.name,
      company: data?.company,
      email: data?.email,
      countryCode: data?.countryCode,
      phone: data?.phone,
      url: data?.url,
      logo: data?.logo,

      bankName: data?.bankName,
      accountNumber: data?.accountNumber,
      ifscCode: data?.ifscCode,
      gstNumber: data?.gstNumber,
      sacCode: data?.sacCode,
      invoiceNumberPrefix: data?.invoiceNumberPrefix || "Inv-",
      voucherNumberPrefix: data?.voucherNumberPrefix || "Vcr-",

      address: data?.address,
      city: data?.city,
      state: data?.state,
      country: data?.country || "India",
      pinCode: data?.pinCode,
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "portals/update";
    let reqOBJ = values;

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);
      data?.success
        ? message.success("Settings updated successful!")
        : message.error("Oops! Something went wrong.");
    });
  };

  const handleUpload = (logo) => {
    form.setFieldsValue({ logo });
  };

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <React.Fragment>
            <SettingOutlined style={{ marginRight: "4px" }} /> Settings
          </React.Fragment>
        }
        ghost={false}
      />
      <Form
        name="settings"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        className="signin-form"
        {...layout}
      >
        <Row>
          <Col span={8}>
            <Form.Item label="Your Name" name="name">
              <Input placeholder="First and Last Name" />
            </Form.Item>

            <Form.Item label="Agency Name" name="company">
              <Input placeholder="Enter Agency Name" />
            </Form.Item>

            <Form.Item label="Email Id" name="email">
              <Input placeholder="Enter Email Id" disabled />
            </Form.Item>

            <Form.Item label="Phone Number" name="phone">
              <Input
                addonBefore={
                  <Form.Item name="countryCode" noStyle>
                    <Select style={{ width: 70 }} disabled>
                      <Select.Option value="+91">+91</Select.Option>
                    </Select>
                  </Form.Item>
                }
                placeholder="Enter Phone Number"
                disabled
              />
            </Form.Item>

            <Form.Item label="Password" name="password" hidden>
              <Input type="password" placeholder="Enter Password" disabled />
            </Form.Item>

            <Form.Item label="Website URL" name="url">
              <Input
                placeholder="Enter URL"
                disabled
                addonBefore={
                  <Form.Item noStyle>{window.origin}/website/</Form.Item>
                }
              />
            </Form.Item>

            <Form.Item label="Logo" name="logo">
              <UploadImg
                path="uploadfile"
                type="file-input"
                handleUpload={handleUpload}
                image={data?.logo}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="GST Number" name="gstNumber">
              <Input placeholder="Enter GST Number" />
            </Form.Item>

            <Form.Item label="Bank Name" name="bankName">
              <Input placeholder="Enter Bank Name" />
            </Form.Item>

            <Form.Item label="Account Number" name="accountNumber">
              <Input placeholder="Enter Account Number" />
            </Form.Item>

            <Form.Item label="IFSC Code" name="ifscCode">
              <Input placeholder="Enter IFSC Code" />
            </Form.Item>

            <Form.Item label="SAC Code" name="sacCode">
              <Input placeholder="Enter SAC Code" />
            </Form.Item>

            <Form.Item label="Invoice Prefix" name="invoiceNumberPrefix">
              <Input placeholder="Enter Invoice Prefix" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Address" name="address">
              <Input placeholder="Enter Address" />
            </Form.Item>

            <Form.Item label="City" name="city">
              <Input placeholder="Enter City" />
            </Form.Item>

            <Form.Item label="State" name="state">
              <Input placeholder="Enter State" />
            </Form.Item>

            <Form.Item label="Country" name="country">
              <Select placeholder="Select Country" showSearch>
                {countryList?.map((x, key) => (
                  <Select.Option value={x?.name} key={key}>
                    {x?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Postal Code" name="pinCode">
              <Input placeholder="Enter Postal Code" />
            </Form.Item>

            <Form.Item label="Voucher Prefix" name="voucherNumberPrefix">
              <Input placeholder="Enter Voucher Prefix" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          wrapperCol={{ xs: { offset: 0 }, xl: { offset: 2 } }}
          className="btn-row"
        >
          <Button
            icon={<EditOutlined />}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Update Profile
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Settings;
