import React, { useEffect, useState } from "react";
import { Button, Drawer, Modal, message, Table, Space } from "antd";
import {
  FileOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import fetchData from "../../services/requester";
import PageAdd from "../../components/website/page-add";

const ManagePages = () => {
  const [data, setData] = useState([]);
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const [isConfirmLoading, setConfirmLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const getData = () => {
    let reqURL = "pages";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data || []);
        setLoading(false);
      },
      "GET"
    );
  };

  const handleMenuClick = (key, item) => {
    setDetails(item);
    switch (key) {
      case "edit":
        setIsEditMode(true);
        showDrawer();
        break;

      case "delete":
        setConfirmVisible(true);
        break;

      default:
        break;
    }
  };

  const onClose = () => {
    setDetails("");
    setIsEditMode(false);
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const handleAdd = () => {
    message.success(
      !isEditMode ? "Record added successfully" : "Record updated successfully"
    );
    setVisible(false);
    setIsEditMode(false);
    getData();
  };

  const handleDelete = () => {
    setConfirmLoading(true);
    const { pageId } = details;
    let reqURL = "pages/delete/" + pageId;
    let reqOBJ = "";
    fetchData(reqURL, reqOBJ, (data) => {
      setConfirmVisible(false);
      setConfirmLoading(false);
      if (data?.success) {
        message.success("Record deleted successfully.");
        setLoading(true);
        getData();
      } else {
        message.error(data?.title || "Oops! Something went wrong.");
      }
    });
  };

  const columns = [
    {
      title: "Page Title",
      dataIndex: "pageTitle",
      key: "pageTitle",
    },
    {
      title: "Page Name",
      dataIndex: "pageName",
      key: "pageName",
    },
    {
      title: "Actions",
      render: (text, record) => (
        <Space>
          <Button
            style={{ border: "none", height: "auto" }}
            icon={<EditOutlined />}
            onClick={() => handleMenuClick("edit", record)}
          />
          <Button
            style={{ border: "none", height: "auto" }}
            icon={<DeleteOutlined />}
            onClick={() => handleMenuClick("delete", record)}
          />
        </Space>
      ),
      width: 50,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <React.Fragment>
            <FileOutlined style={{ marginRight: "4px" }} /> Pages
          </React.Fragment>
        }
        extra={[
          <Button
            key={3}
            type="primary"
            icon={<PlusOutlined />}
            onClick={showDrawer}
          >
            Add Page
          </Button>,
        ]}
        ghost={false}
        style={{ border: "0px none" }}
      />

      <Table
        columns={columns}
        rowKey={(record) => record?.pageId}
        dataSource={data || []}
        loading={loading}
        size="small"
        bordered
        pagination={false}
      />

      <Drawer
        title={!isEditMode ? "Add Page" : "Update Page"}
        placement="right"
        onClose={onClose}
        open={visible}
        size={"large"}
      >
        <PageAdd
          isEditMode={isEditMode}
          {...details}
          key={visible}
          handleAdd={handleAdd}
        />
      </Drawer>

      <Modal
        title={"Confirm Delete"}
        open={isConfirmVisible}
        onCancel={() => setConfirmVisible(false)}
        centered
        width={320}
        footer={[
          <div style={{ textAlign: "center" }} key="delete">
            <Button
              type="primary"
              loading={isConfirmLoading}
              danger
              onClick={() => handleDelete()}
            >
              Confirm
            </Button>
          </div>,
        ]}
      >
        <div style={{ margin: "16px 0px" }}>
          Are you sure to delete this Item?
        </div>
      </Modal>
    </div>
  );
};

export default ManagePages;
