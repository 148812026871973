import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  List,
  Row,
  Col,
  Button,
  Typography,
  Dropdown,
  Drawer,
  Tooltip,
  Avatar,
  Modal,
  message,
  Table,
  Tag,
} from "antd";
import {
  AuditOutlined,
  PlusOutlined,
  MenuOutlined,
  FilterOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import fetchData from "../services/requester";
import CommonFilters from "../components/common-filters";
import { Date, Currency } from "../helpers/formatter";
import InvoiceAdd from "../components/invoice-add";

const Invoices = (props) => {
  const [data, setData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const [isConfirmLoading, setConfirmLoading] = useState(false);
  const [isFiltersVisible, setFiltersVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const location = useLocation();
  const [type, setType] = useState(
    location?.pathname === "/invoices" ? "Invoice" : "Voucher"
  );
  const [isConvertMode, setIsConvertMode] = useState(false);

  const getData = () => {
    let reqURL = "invoices" + (type === "Invoice" ? "/" + type : "");
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data || []);
        setDefaultData(data || []);
        setLoading(false);
      },
      "GET"
    );
  };

  const invoiceMenuItems = [
    {
      label: "View " + type,
      key: "view",
      icon: <EyeOutlined />,
    },
    {
      label: "Share " + type,
      key: "share",
      icon: <WhatsAppOutlined />,
    },
    {
      label: "Edit " + type,
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Delete " + type,
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];

  const voucherMenuItems = [
    {
      label: "View " + type,
      key: "view",
      icon: <EyeOutlined />,
    },
    {
      label: "Share " + type,
      key: "share",
      icon: <WhatsAppOutlined />,
    },
    {
      label: "Edit " + type,
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Convert to Invoice",
      key: "convert",
      icon: <AuditOutlined />,
    },
    {
      label: "Delete " + type,
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];

  const handleMenuClick = (key, item) => {
    setDetails(item);
    switch (key) {
      case "view":
        handlePreview(item);
        break;

      case "share":
        handleShare(item);
        break;

      case "edit":
        setIsEditMode(true);
        showDrawer();
        break;

      case "convert":
        setIsConvertMode(true);
        setIsEditMode(true);
        showDrawer();
        break;

      case "delete":
        setConfirmVisible(true);
        break;

      default:
        break;
    }
  };

  const onClose = () => {
    setIsEditMode(false);
    setIsConvertMode(false);
    setVisible(false);
  };

  const onFiltersClose = () => {
    setFiltersVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const showFiltersDrawer = () => {
    setFiltersVisible(true);
  };

  const handleAdd = (response) => {
    message.success(
      !isEditMode
        ? type + " added successfully"
        : !isConvertMode
        ? type + " updated successfully"
        : "Invoice converted successfully"
    );
    setVisible(false);
    setIsEditMode(false);
    setIsConvertMode(false);
    getData();
  };

  const handleDelete = () => {
    setConfirmLoading(true);
    const { invoiceId } = details;
    let reqURL = "invoices/delete/" + invoiceId;
    let reqOBJ = "";
    fetchData(reqURL, reqOBJ, (data) => {
      setConfirmVisible(false);
      setConfirmLoading(false);
      if (data?.success) {
        message.success(type + " deleted successfully.");
        setLoading(true);
        getData();
      } else {
        message.error(data?.title || "Oops! Something went wrong.");
      }
    });
  };

  const handleFilters = (filters) => {
    setFiltersVisible(false);

    const filteredData = defaultData?.filter(
      ({ invoiceTitle, bookingFor, name, email, phone }) =>
        (!filters?.title ||
          invoiceTitle
            ?.toLowerCase()
            ?.includes(filters?.title?.toLowerCase())) &&
        (!filters?.bookingFor ||
          bookingFor
            ?.toLowerCase()
            ?.includes(filters?.bookingFor?.toLowerCase())) &&
        (!filters?.name ||
          name?.toLowerCase()?.includes(filters?.name?.toLowerCase())) &&
        (!filters?.email ||
          email?.toLowerCase()?.includes(filters?.email?.toLowerCase())) &&
        (!filters?.phone || phone?.includes(filters?.phone))
    );

    setData(filteredData);
  };

  const getColumns = () => {
    let columns = [
      {
        title: type + " Date",
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        render: (text, record) =>
          type === "Invoice"
            ? Date(record?.invoiceDate)
            : Date(record?.voucherDate),
      },
      {
        title: type + " Number",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        render: (text, record) =>
          type === "Invoice"
            ? record?.invoiceNumberPrefix + record?.invoiceNumber
            : record?.voucherNumberPrefix + record?.voucherNumber,
      },
      {
        title: type + " Title",
        dataIndex: "invoiceTitle",
        key: "invoiceTitle",
      },
      {
        title: "Customer",
        dataIndex: "name",
        key: "name",
        render: (text) => (
          <span style={{ textTransform: "capitalize" }}>{text}</span>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        render: (text, record) => record?.countryCode + text,
      },
      {
        title: "Status",
        dataIndex: "invoiceStatus",
        key: "invoiceStatus",
      },
      {
        title: "Due Date",
        dataIndex: "invoiceDueDate",
        key: "invoiceDueDate",
        render: (text) => Date(text),
      },
      {
        title: "Amount",
        dataIndex: "invoiceAmount",
        key: "invoiceAmount",
        render: (text) => Currency(text),
      },
      {
        title: "Actions",
        render: (text, record) => (
          <div className="d-flex align-items-center justify-content-center">
            <Dropdown
              menu={{
                items:
                  record?.type === "Invoice"
                    ? invoiceMenuItems
                    : voucherMenuItems,
                onClick: (e) => handleMenuClick(e.key, record),
              }}
              icon
            >
              <Button className="reset-icon-btn">
                <MenuOutlined />
              </Button>
            </Dropdown>
          </div>
        ),
        width: 50,
      },
    ];

    if (type === "Voucher") {
      columns = columns.filter(
        (x) =>
          x?.key !== "invoiceDueDate" &&
          x?.key !== "invoiceStatus" &&
          x?.key !== "invoiceAmount"
      );
    }

    return columns;
  };

  const handleShare = (item) => {
    const { url } = props;
    const { countryCode, phone, invoiceId } = item;

    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    let path = "/website/" + url + "/invoice-details/" + invoiceId;
    let message = "Your Proposal - ";
    let whatsApp = "";

    if (isMobile) {
      whatsApp = "whatsapp://send?phone=" + countryCode + phone + "&text=";
    } else {
      whatsApp =
        "https://web.whatsapp.com/send?phone=" + countryCode + phone + "&text=";
    }

    window.open(
      whatsApp + encodeURIComponent(message + window.origin + path),
      "_blank"
    );
  };

  const handlePreview = (item) => {
    const { url } = props;
    const { invoiceId } = item;
    let path =
      "/website/" +
      url +
      "/" +
      type.toLocaleLowerCase() +
      "-details/" +
      invoiceId;

    window.open(window.origin + path, "_blank");
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <React.Fragment>
            <AuditOutlined style={{ marginRight: "4px" }} /> {type}s
          </React.Fragment>
        }
        extra={[
          <Tooltip title="Filters" placement="bottom" key={2}>
            <Button
              key={2}
              type="default"
              icon={<FilterOutlined />}
              onClick={showFiltersDrawer}
            >
              Filters
            </Button>
          </Tooltip>,
          <Button
            key={3}
            type="primary"
            icon={<PlusOutlined />}
            onClick={showDrawer}
          >
            Add {type}
          </Button>,
        ]}
        ghost={false}
      />

      <Table
        columns={getColumns()}
        rowKey={(record) => record?.invoiceId}
        dataSource={data || []}
        loading={loading}
        size="small"
      />

      <Drawer
        title={
          !isEditMode
            ? "Add " + type
            : !isConvertMode
            ? "Update " + type
            : "Convert to Invoice"
        }
        placement="right"
        onClose={onClose}
        open={visible}
        width={"48%"}
      >
        <InvoiceAdd
          isEditMode={isEditMode}
          isConvertMode={isConvertMode}
          portal={props}
          {...details}
          type={type}
          key={visible}
          handleAdd={handleAdd}
        />
      </Drawer>

      <Drawer
        title="Filters"
        placement="right"
        onClose={onFiltersClose}
        open={isFiltersVisible}
      >
        <CommonFilters handleFilters={handleFilters} screen={"leads"} />
      </Drawer>

      <Modal
        title={"Confirm Delete"}
        open={isConfirmVisible}
        onCancel={() => setConfirmVisible(false)}
        centered
        width={320}
        footer={[
          <div style={{ textAlign: "center" }} key="delete">
            <Button
              type="primary"
              loading={isConfirmLoading}
              danger
              onClick={() => handleDelete()}
            >
              Confirm
            </Button>
          </div>,
        ]}
      >
        <div style={{ margin: "16px 0px" }}>
          Are you sure to delete this Item?
        </div>
      </Modal>
    </div>
  );
};

export default Invoices;
