import React from "react";
import HomeBanner from "../../components/website/home-banner";
import HomeDeals from "../../components/website/home-deals";
import HomeTestimonials from "../../components/website/home-testimonials";
import HomePackages from "../../components/website/home-packages";
import HomeDestinations from "../../components/website/home-destinations";
import HomeWhyus from "../../components/website/home-whyus";

const WebHome = (props) => {
  return (
    <div className="web-home" style={{ minHeight: "100vh" }}>
      {props?.promotions && <HomeBanner />}
      <div className="container">
        {props?.deals && <HomeDeals {...props} />}
        {props?.destinations && <HomeDestinations {...props} />}
        {props?.whyus && <HomeWhyus {...props} />}
        {props?.packages && <HomePackages {...props} />}
        {props?.testimonials && <HomeTestimonials {...props} />}
      </div>
    </div>
  );
};

export default WebHome;
