import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  InputNumber,
  Select,
  Row,
  Col,
  Radio,
  message,
} from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";
import dayjs from "dayjs";
import CustomersDropDown from "./customers-dropdown";
import {
  leadSourceList,
  leadStatusList,
  leadTypeList,
  tripTypeList,
} from "../helpers/lookups";
import { ResDate } from "../helpers/formatter";

const LeadAdd = (props) => {
  const [data, setData] = useState("");
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [customerType, setCustomerType] = useState("Individual");

  const getData = () => {
    const { leadId } = props;
    let reqURL = "leads/detail/" + leadId;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setDefaultData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      customerId: data?.customerId || "",
      title: data?.title || "",
      leadType: data?.leadType || "",
      source: data?.source || "",
      tripType: data?.tripType || "",
      bookingFor: data?.bookingFor || "",
      status: data?.status || "",
      startDate: data?.startDate && dayjs(data?.startDate),
      duration: data?.duration || "",
      followupDate: data?.followupDate && dayjs(data?.followupDate),
      budget: data?.budget,
      adults: data?.adults,
      child: data?.child,
      infant: data?.infant,
      assignedTo: data?.assignedTo || "",
      comments: data?.comments || "",
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "leads/" + (props.isEditMode ? "update" : "add");
    let reqOBJ = values;

    reqOBJ.startDate = ResDate(values?.startDate);
    reqOBJ.followupDate = ResDate(values?.followupDate);

    props?.isEditMode && (reqOBJ.leadId = props?.leadId);

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);
      data?.success
        ? props.handleAdd()
        : message.error("Oops! Something went wrong.");
    });
  };

  const handleCustomer = (customer) => {
    form.setFieldsValue({ customerId: customer });
  };

  const handleValuesChange = (value, values) => {
    if (value["startDate"]) {
      form.setFieldsValue({
        followupDate: dayjs(value["startDate"]).add(-1, "day"),
      });
    }

    if (value["bookingFor"]) {
      setCustomerType(value["bookingFor"]);
    }
  };

  const disabledDate = (current) => {
    return (
      current &&
      current > dayjs(form.getFieldsValue(["startDate"]).startDate).endOf("day")
    );
  };

  useEffect(() => {
    props?.isEditMode && getData();
  }, []);

  const col1 = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const col2 = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const col3 = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };

  return (
    <React.Fragment>
      <Form
        name="lead-add"
        form={form}
        {...col1}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          leadType: "Package",
          tripType: "Domestic",
          bookingFor: "Individual",
          status: "New",
          startDate: dayjs().add(2, "day"),
          duration: 2,
          source: "Call Center",
          followupDate: dayjs().add(1, "day"),
          adults: 1,
          assignedTo: "Sales",
        }}
        onValuesChange={handleValuesChange}
      >
        <Row>
          <Col span={24}>
            <Form.Item label="Booking For" name="bookingFor">
              <Radio.Group>
                <Radio.Button value="Individual">Individual</Radio.Button>
                <Radio.Button value="Corporate">Corporate</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label="Customer"
              name="customerId"
              rules={[
                {
                  required: true,
                  message: "Please Select Customer!",
                },
              ]}
            >
              <CustomersDropDown
                key={props?.customerId}
                {...props}
                customerType={customerType}
                handleCustomer={handleCustomer}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input Lead Title!",
                },
              ]}
            >
              <Input placeholder="Enter Lead Title / Details" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="Lead Type" name="leadType" {...col2}>
              <Select
                placeholder="Select Lead Type"
                options={leadTypeList}
              ></Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Trip Type" name="tripType" {...col3}>
              <Select
                placeholder="Select Trip Type"
                options={tripTypeList}
              ></Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="Start Date" name="startDate" {...col2}>
              <DatePicker format={"DD/MM/YYYY"} allowClear={false} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Duration" name="duration" {...col3}>
              <InputNumber placeholder="Duration" min={1} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="Lead Source" name="source" {...col2}>
              <Select
                placeholder="Select Lead Source"
                options={leadSourceList}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status" name="status" {...col3}>
              <Select
                placeholder="Select Status"
                options={leadStatusList}
              ></Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="Followup Date" name="followupDate" {...col2}>
              <DatePicker
                format={"DD/MM/YYYY"}
                disabledDate={disabledDate}
                allowClear={false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Budget" name="budget" {...col3}>
              <InputNumber style={{ width: "100%" }} placeholder="INR" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Row>
              <Col span={14}>
                <Form.Item
                  label="Pax"
                  name="adults"
                  labelCol={{
                    span: 17,
                  }}
                  wrapperCol={{
                    span: 7,
                  }}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Adult"
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="child"
                  wrapperCol={{
                    span: 24,
                  }}
                >
                  <InputNumber
                    style={{ width: "88%", marginLeft: "8px" }}
                    placeholder="Child"
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="infant"
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                >
                  <InputNumber
                    style={{ width: "88%", marginLeft: "8px" }}
                    placeholder="Infant"
                    min={0}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item label="Assign To" name="assignedTo" {...col3}>
              <Select>
                <Select.Option value="sales">Sales</Select.Option>
                <Select.Option value="operations">Operations</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="Comments" name="comments">
              <Input.TextArea placeholder="Enter Comments" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          wrapperCol={{
            offset: 5,
            span: 15,
          }}
          className="btn-row"
        >
          <Button
            icon={!props?.isEditMode ? <PlusOutlined /> : <EditOutlined />}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {!props?.isEditMode ? "Add Lead" : "Update Lead"}
          </Button>
          {/* {!props?.isEditMode && (
            <Button icon={<FileAddOutlined />} type="default">
              Add Proposal
            </Button>
          )} */}
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default LeadAdd;
