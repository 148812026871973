import React from "react";
import { Layout } from "antd";

const AppFooter = () => {
  const { Footer } = Layout;
  return (
    <Footer style={{ textAlign: "center", opacity: "0.6" }}>
      Copyright 2024-2025 by BizVeda
    </Footer>
  );
};

export default AppFooter;
