import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetchData from "../../services/requester-website";
import WebLoader from "../../components/website/web-loader";
import parse from "html-react-parser";
import { Form, Input, Button, message } from "antd";
import { imagePath } from "../../helpers/global";
import { Currency, Date, Time } from "../../helpers/formatter";
import { FilePdfOutlined, CopyOutlined } from "@ant-design/icons";

const QuickProposalDetails = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState("");
  const [quickReplies, setQuickReplies] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const getData = () => {
    const { id } = params;
    let reqURL = "quick-proposals/detail/" + id;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setLoading(false);
          getQuickReplies();
        }
      },
      "GET"
    );
  };

  const getQuickReplies = () => {
    const { id } = params;
    let reqURL = "quick-proposals/quick-replies/" + id;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setQuickReplies(data || []);
      },
      "GET"
    );
  };

  const onFinish = (values) => {
    let reqURL = "quick-proposals/quick-replies/add";
    let reqOBJ = values;

    reqOBJ.leadId = data?.leadId;
    reqOBJ.customerId = data?.customerId;
    reqOBJ.quickProposalId = data?.quickProposalId;
    reqOBJ.quickReplyType = "incoming";

    fetchData(reqURL, reqOBJ, (data) => {
      if (data?.success) {
        message.success("Your message has been sent successfully.");
        form.setFieldsValue({
          quickReply: "",
        });
        getQuickReplies();
      } else {
        message.error("Oops! Something went wrong.");
      }
    });
  };

  const hanldePrint = () => {
    window.print();
  };

  const copyToClipboard = () => {
    var dummy = document.createElement("input"),
      text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="web-details quick-proposal-details">
      {!loading && (
        <div className="container">
          <div className="pt-4">
            <div className="row">
              <div className="col-lg-8">
                {data?.imageURL && (
                  <div className="web-details-image">
                    <img src={imagePath() + data?.imageURL} alt={data?.title} />
                  </div>
                )}

                <div className="web-details-sec">
                  <h3>{data?.title}</h3>

                  {data?.offerPrice ? (
                    <h4 className="mb-4">
                      Price: {Currency(data?.offerPrice)}
                    </h4>
                  ) : (
                    ""
                  )}

                  <div>{parse("" + data?.details + "")}</div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="quick-proposal-details-btns">
                  <Button
                    icon={<FilePdfOutlined />}
                    onClick={() => hanldePrint()}
                    className="me-2"
                  >
                    Download PDF
                  </Button>
                  <Button
                    icon={<CopyOutlined />}
                    onClick={() => copyToClipboard()}
                    className="ms-2"
                  >
                    Copy & Share Link
                  </Button>
                </div>
                <div className="web-details-info">
                  <div className="web-details-title">
                    <h2>Quick Reply</h2>
                  </div>{" "}
                  <div className="web-details-info-wrap">
                    <Form
                      name="quickproposal-add"
                      form={form}
                      onFinish={onFinish}
                      autoComplete="off"
                    >
                      <Form.Item label="" name="quickReply">
                        <Input.TextArea placeholder="Type your message here..." />
                      </Form.Item>

                      <Button type="primary" htmlType="submit">
                        Reply
                      </Button>
                    </Form>

                    {quickReplies?.map((x, key) => (
                      <div
                        key={key}
                        className="rounded p-3 mt-3"
                        style={{
                          background: "rgb(249, 252, 255)",
                          border: "solid 1px rgb(217, 228, 236)",
                        }}
                      >
                        <p className="mb-2">{x?.quickReply}</p>
                        <span className="text-secondary small">
                          {Date(x?.createdDate)} {Time(x?.createdDate)}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading && <WebLoader />}
    </div>
  );
};

export default QuickProposalDetails;
