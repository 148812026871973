import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Radio,
  InputNumber,
} from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { genderList, countryList, documentTypeList } from "../helpers/lookups";

const InvoicePassengerAdd = (props) => {
  const [data, setData] = useState("");
  const [customerType, setCustomerType] = useState("Individual");
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const setDefaultData = (data) => {
    form.setFieldsValue({
      customerType: data?.customerType || "",
      gender: data?.gender || "",
      customerName: data?.customerName || "",
      email: data?.email || "",
      countryCode: data?.countryCode || "",
      phone: data?.phone || "",
      country: data?.country || "",
      city: data?.city || "",
      address: data?.address || "",
      postalCode: data?.postalCode || "",
      documentType: data?.documentType || "",
      documentNumber: data?.documentNumber || "",
      nationality: data?.nationality || "",
      issuingCountry: data?.issuingCountry || "",
      panNumber: data?.panNumber || "",
      gstNumber: data?.gstNumber || "",
      age: data?.age || 0,
    });
  };

  const handleValuesChange = (value, values) => {
    if (value["customerType"]) {
      setCustomerType(value["customerType"]);
    }
  };

  const onFinish = (values) => {
    let passenger = values;
    if (props?.isEditMode) {
      passenger.guId = props?.guId;
      passenger.invoicePassengerId = props?.invoicePassengerId;
      props.handleUpdatePassenger(passenger);
    } else {
      passenger.guId = generateGUID();
      props.handleAddPassenger(passenger);
    }
  };

  const generateGUID = () => {
    const s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  useEffect(() => {
    props?.isEditMode && setDefaultData(props);
  }, []);

  return (
    <Form
      name="customer-add"
      form={form}
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
      autoComplete="off"
      {...layout}
      initialValues={{
        customerType,
        country: "India",
        issuingCountry: "India",
        nationality: "India",
        countryCode: "+91",
      }}
    >
      <Row>
        <Col span={12}>
          <Form.Item label="Type" name="customerType" hidden>
            <Radio.Group>
              <Radio.Button value="Individual">Individual</Radio.Button>
              <Radio.Button value="Corporate">Corporate</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="Gender" name="gender">
            <Select placeholder="Select Gender">
              {genderList?.map((x, key) => (
                <Select.Option value={x?.value} key={key}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            label="Name"
            name="customerName"
            rules={[
              {
                required: true,
                message:
                  customerType === "Individual"
                    ? "Please input First and Last Name!"
                    : "Please input Corporate Name",
              },
            ]}
          >
            <Input
              placeholder={
                customerType === "Individual"
                  ? "First and Last Name"
                  : "Corporate / Company Name"
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Age" name="age">
            <InputNumber placeholder="Age in Years" className="in-100" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="Email" name="email">
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Phone" name="phone">
            <Input
              addonBefore={
                <Form.Item name="countryCode" noStyle>
                  <Select style={{ width: 70 }}>
                    <Select.Option value="+91">+91</Select.Option>
                  </Select>
                </Form.Item>
              }
              placeholder="Phone Number"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="Country" name="country" hidden>
            <Select placeholder="Select Country" showSearch>
              {countryList?.map((x, key) => (
                <Select.Option value={x?.name} key={key}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="City" name="city" hidden>
            <Input placeholder="City" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="Address" name="address" hidden>
            <Input placeholder="Address" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Postal Code" name="postalCode" hidden>
            <Input placeholder="Postal Code" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="Document" name="documentType">
            <Select placeholder="Select Document Type">
              {documentTypeList?.map((x, key) => (
                <Select.Option value={x?.name} key={key}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Number" name="documentNumber">
            <Input placeholder="Document Number" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="Issued by" name="issuingCountry">
            <Select placeholder="Select Issuing Country" showSearch>
              {countryList?.map((x, key) => (
                <Select.Option value={x?.name} key={key}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Nationality" name="nationality">
            <Select placeholder="Select Nationality" showSearch>
              {countryList?.map((x, key) => (
                <Select.Option value={x?.name} key={key}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="PAN Number" name="panNumber" hidden>
            <Input placeholder="PAN Number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="GST Number" name="gstNumber" hidden>
            <Input placeholder="GST Number" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        wrapperCol={{
          offset: 4,
          span: 16,
        }}
      >
        <Button
          icon={!props?.isEditMode ? <PlusOutlined /> : <EditOutlined />}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {(!props?.isEditMode ? "Add " : "Update") + " Guest"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default InvoicePassengerAdd;
