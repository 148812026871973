import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetchData from "../../services/requester-website";
import parse from "html-react-parser";
import WebLoader from "../../components/website/web-loader";
import { imagePath } from "../../helpers/global";

const WebPage = ({ menu }) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const getData = () => {
    const { page } = params;

    let id = menu?.find((x) => x?.pageName === page)?.pageId;
    let reqURL = "pages/detail/" + id;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  useEffect(() => {
    menu.length > 0 && getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="web-page">
      <div className="web-page-title">
        <div className="container">
          <h1>{data?.pageTitle}</h1>
          {data?.pageSubTitle && (
            <h5 className="mt-3 text-secondary">{data?.pageSubTitle}</h5>
          )}
        </div>
      </div>

      {!loading && (
        <div className="container">
          <div className="pt-3">
            {data?.imageURL && (
              <img
                src={imagePath() + data?.imageURL}
                alt={data?.pageTitle}
                className="web-page-banner-img mb-5 rounded"
              />
            )}
            <div>{parse("" + data?.pageContent + "")}</div>
          </div>
        </div>
      )}

      {loading && <WebLoader />}
    </div>
  );
};

export default WebPage;
