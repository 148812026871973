export const defaultSettings = {
  deals: true,
  packages: true,
  destinations: true,
  testimonials: true,
  whyus: true,
  promotions: true,
  dealsTitle: "Hot Picks",
  dealsSubTitle:
    "Unbeatable Deals Guaranteed to Brighten Your Day, Elevate Your Mood, and Lighten the Load on Your Wallet!",
  packagesTitle: "Flash Getaways",
  packagesSubTitle:
    "Seize Today's Exclusive Packages Before They Disappear Into the Ether!",
  destinationsTitle: "Explore Our Top Destinations",
  destinationsSubTitle:
    "Journey Through Iconic Landmarks and Exotic Hideaways in Our Top Picks!",
  whyusTitle: "Why Choose Us",
  whyusSubTitle: "We Are The Best For Providing The Travel Arrangement.",
  testimonialsTitle: "Echoes of Happy Customers",
  testimonialsSubTitle:
    "Unveiling the Heartwarming Tales That Illuminate the Path to Our Success!",
  copyright: "Copyright 2024-2025 by BizVeda",
};
