import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import fetchData from "../../services/requester-website";
import WebLoader from "../../components/website/web-loader";
import { Date, Time, Currency, DateTime } from "../../helpers/formatter";

const InvoiceDetails = (props) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const location = useLocation();
  const [type, setType] = useState(
    location.pathname.includes("invoice") ? "Invoice" : "Voucher"
  );

  const getData = () => {
    const { id } = params;
    let reqURL = "invoices/detail/" + id;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  const hanldePrint = () => {
    window.print();
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="web-details invoice-details">
      {!loading && (
        <div className="container">
          <div className="pt-4">
            <div className="row invoice-action-btn">
              <div className="col-lg-6">
                <h2 className="mb-4 fw-bold" style={{ fontSize: "1.5rem" }}>
                  {type}
                </h2>
              </div>
              <div className="col-lg-6">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => hanldePrint()}
                  style={{
                    float: "right",
                    width: "auto",
                    height: "auto",
                    fontWeight: "normal",
                    padding: "4px 12px",
                    fontSize: "0.9rem",
                  }}
                >
                  Download PDF
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="web-details-sec">
                  <h2 style={{ fontSize: "1.6rem", fontWeight: "600" }}>
                    {props?.company}
                  </h2>

                  <div className="mt-2">
                    <b>Address : </b>
                    {props?.address}
                    {props?.city && ", " + props?.city}
                    {props?.state && ", " + props?.state}
                    {props?.pinCode && ", " + props?.pinCode}
                    {props?.country && ", " + props?.country}
                  </div>

                  {props?.email && (
                    <div className="mt-1">
                      <b>Email : </b>
                      {props?.email}
                    </div>
                  )}

                  {props?.phone && (
                    <div className="mt-1">
                      <b>Phone : </b>
                      {props?.countryCode + props?.phone}
                    </div>
                  )}

                  <div className="mt-1">
                    {props?.gstNumber && (
                      <React.Fragment>
                        <b>GST : </b>
                        {props?.gstNumber}
                        {props?.panNumber && ", "}
                      </React.Fragment>
                    )}
                    {props?.panNumber && (
                      <React.Fragment>
                        <b> PAN : </b>
                        {props?.panNumber}
                      </React.Fragment>
                    )}
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-4">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      className="web-details-sec mb-0"
                      style={{ height: "100%" }}
                    >
                      {type === "Invoice" && <b>{type} To:</b>}
                      <div className="mt-2 fw-bold">{data?.name}</div>
                      <div className="mt-1">
                        <b>Email : </b>
                        {data?.email}
                      </div>
                      <div className="mt-1">
                        <b>Phone : </b>
                        {data?.countryCode + data?.phone}
                      </div>

                      <div className="mt-1">
                        {data?.address && <b>Address : </b>}
                        {data?.address}
                        {data?.city && ", " + data?.city}
                        {data?.postalCode && ", " + data?.postalCode}
                      </div>
                      <div className="mt-1">
                        {data?.gstNumber && (
                          <React.Fragment>
                            <b>GST : </b>
                            {data?.gstNumber}
                            {data?.panNumber && ", "}
                          </React.Fragment>
                        )}
                        {data?.panNumber && (
                          <React.Fragment>
                            <b> PAN : </b>
                            {data?.panNumber}
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="web-details-sec mb-0"
                      style={{ height: "100%" }}
                    >
                      <b>{type} Details:</b>
                      {type === "Invoice" && (
                        <React.Fragment>
                          <div className="mt-2">
                            <b>{type} Number : </b>
                            {data?.invoiceNumberPrefix + data?.invoiceNumber}
                          </div>
                          <div className="mt-1">
                            <b>{type} Date : </b>
                            {Date(data.invoiceDate)}
                          </div>

                          <div className="mt-1">
                            <b>{type} Due Date : </b>
                            {Date(data.invoiceDueDate)}
                          </div>
                          <div className="mt-1">
                            <b>{type} Amount : </b>
                            {Currency(data.invoiceAmount)}
                          </div>
                          <div className="mt-1">
                            <b>{type} Status : </b>
                            {data.paymentStatus}
                          </div>
                        </React.Fragment>
                      )}

                      {type === "Voucher" && (
                        <React.Fragment>
                          <div className="mt-2">
                            <b>{type} Number : </b>
                            {data?.voucherNumberPrefix + data?.voucherNumber}
                          </div>
                          <div className="mt-1">
                            <b>{type} Date : </b>
                            {Date(data.voucherDate)}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {data?.invoiceItems && data?.invoiceItems.length > 0 && (
                <div className="col-lg-12">
                  <div className="web-details-sec">
                    {data?.invoiceItems &&
                      data?.invoiceItems.map((item, key) => (
                        <div
                          className={
                            type === "Invoice"
                              ? "border-bottom pb-3 mb-3"
                              : "pb-3"
                          }
                          key={key}
                        >
                          <div className="row small">
                            <div className="col-lg-8">
                              {item?.business === "Hotel" && (
                                <React.Fragment>
                                  {" "}
                                  <div
                                    style={{
                                      fontSize: "1.2rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.business} - {item?.itemName}
                                  </div>
                                  <div>
                                    {item?.itemLocation} {item?.itemAddress}
                                  </div>
                                  <div className="mt-2">
                                    {Date(item?.checkInDate)} -{" "}
                                    {Date(item?.checkOutDate)} -{" "}
                                    {item?.noOfNights} night(s)
                                  </div>
                                  <div>
                                    {item?.noOfRooms} x {item?.roomType} Room(s)
                                  </div>
                                </React.Fragment>
                              )}

                              {(item?.business === "Package" ||
                                item?.business === "Activity" ||
                                item?.business === "Other") && (
                                <React.Fragment>
                                  <div
                                    style={{
                                      fontSize: "1.2rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.business} - {item?.itemName}
                                  </div>
                                  <div>
                                    {item?.itemLocation} {item?.itemAddress}
                                  </div>
                                  <div className="mt-2">
                                    {Date(item?.checkInDate)}
                                  </div>
                                  <div>
                                    {item?.itemType}

                                    {item?.business === "Package" &&
                                      " - " + item?.duration}

                                    {item?.business === "Activity" &&
                                      " - " + item?.noOfGuests + " Guest(s)"}
                                  </div>
                                </React.Fragment>
                              )}

                              {item?.business === "Transfer" && (
                                <React.Fragment>
                                  <div
                                    style={{
                                      fontSize: "1.2rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.business} - {item?.pickupLocation} to{" "}
                                    {item?.dropoffLocation}
                                  </div>

                                  <div>
                                    {DateTime(item?.startTime)} -{" "}
                                    {Time(item?.endTime)}
                                  </div>
                                  <div>
                                    {item?.itemType} - {item?.duration} -{" "}
                                    {item?.tripType} - {item?.noOfGuests}{" "}
                                    Guest(s)
                                  </div>
                                </React.Fragment>
                              )}

                              {item?.business === "Flight" && (
                                <React.Fragment>
                                  <div
                                    style={{
                                      fontSize: "1.2rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.business} - {item?.fromLocation} (
                                    {item?.fromLocationCode}) -{" "}
                                    {item?.toLocation} ({item?.toLocationCode}){" "}
                                    {item?.tripType
                                      ? " - " +
                                        item?.fromLocation +
                                        " (" +
                                        item?.fromLocationCode +
                                        ")"
                                      : ""}
                                  </div>

                                  <div>
                                    {item?.fromLocation} (
                                    {item?.fromLocationCode}) -{" "}
                                    {item?.toLocation} ({item?.toLocationCode})
                                  </div>

                                  <div>
                                    {Date(item?.departFromDate)}{" "}
                                    {item?.departFromTime}
                                    {item?.departToDate ? " | " : ""}
                                    {Date(item?.departToDate)}{" "}
                                    {item?.departToTime}
                                    {item?.departDuration ? " | " : ""}
                                    {item?.departDuration}
                                    {item?.departStops ? " | " : ""}
                                    {item?.departStops}
                                  </div>

                                  <div>
                                    {item?.departAirline}
                                    {item?.departFlightNumber ? " | " : ""}
                                    {item?.departFlightNumber}
                                    {item?.departClass ? " | " : ""}
                                    {item?.departClass}
                                    {item?.departStopsDetails ? " | " : ""}
                                    {item?.departStopsDetails}
                                  </div>
                                  {item?.tripType ? (
                                    <React.Fragment>
                                      <div className="mt-3">
                                        {item?.toLocation} (
                                        {item?.toLocationCode}) -{" "}
                                        {item?.fromLocation} (
                                        {item?.fromLocationCode})
                                      </div>

                                      <div>
                                        {Date(item?.returnFromDate)}{" "}
                                        {item?.returnFromTime}
                                        {item?.returnToDate ? " | " : ""}
                                        {Date(item?.returnToDate)}{" "}
                                        {item?.returnToTime}
                                        {item?.returnDuration ? " | " : ""}
                                        {item?.returnDuration}
                                        {item?.returnStops ? " | " : ""}
                                        {item?.returnStops}
                                      </div>

                                      <div>
                                        {item?.returnAirline}
                                        {item?.returnFlightNumber ? " | " : ""}
                                        {item?.returnFlightNumber}
                                        {item?.returnClass ? " | " : ""}
                                        {item?.returnClass}
                                        {item?.returnStopsDetails ? " | " : ""}
                                        {item?.returnStopsDetails}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}

                                  {/* <div>{item?.adults}</div>
                                <div>{item?.child}</div>
                                <div>{item?.infant}</div> */}
                                </React.Fragment>
                              )}

                              {item?.itemDescription && (
                                <div className="mt-1">
                                  {item?.itemDescription}
                                </div>
                              )}

                              {item?.confirmationNumber && (
                                <div className="mt-1">
                                  {item?.confirmationNumber}
                                </div>
                              )}

                              <div className="mt-3">
                                {item?.invoicePassengers &&
                                  item?.invoicePassengers.length > 0 &&
                                  item?.invoicePassengers?.map((x, key) => (
                                    <div key={key} className="text-secondary">
                                      Guest {key + 1} : {x?.customerName} -{" "}
                                      {x?.email} - {x?.phone}
                                    </div>
                                  ))}
                              </div>
                            </div>

                            {type === "Invoice" && (
                              <div className="col-lg-4">
                                <div className="row g-1">
                                  <div className="col-lg-7">Base Price</div>
                                  <div className="col-lg-5 text-end">
                                    {Currency(item?.basePrice)}
                                  </div>

                                  {item?.igst ? (
                                    <React.Fragment>
                                      <div className="col-lg-8">IGST</div>
                                      <div className="col-lg-4 text-end">
                                        (+) {Currency(item?.igst)}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment></React.Fragment>
                                  )}

                                  {item?.cgst ? (
                                    <React.Fragment>
                                      <div className="col-lg-8">CGST</div>
                                      <div className="col-lg-4 text-end">
                                        (+) {Currency(item?.cgst)}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment></React.Fragment>
                                  )}

                                  {item?.sgst ? (
                                    <React.Fragment>
                                      <div className="col-lg-8">SGST</div>
                                      <div className="col-lg-4 text-end">
                                        (+) {Currency(item?.sgst)}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment></React.Fragment>
                                  )}

                                  {item?.discount ? (
                                    <React.Fragment>
                                      <div className="col-lg-8">Discount</div>
                                      <div className="col-lg-4 text-end">
                                        (-) {Currency(item?.discount)}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment></React.Fragment>
                                  )}

                                  <div className="col-lg-8 fw-bold">
                                    Total Price
                                  </div>
                                  <div className="col-lg-4 text-end fw-bold">
                                    {Currency(item?.totalPrice)}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}

                    {type === "Invoice" && (
                      <div
                        className="row p-2"
                        // style={{ background: "rgba(0,0,0,0.03)" }}
                      >
                        <div className="col-lg-8"></div>
                        <div className="col-lg-4">
                          <div className="row g-1">
                            <div className="col-lg-7">Base Price</div>
                            <div className="col-lg-5 text-end">
                              {Currency(data?.basePrice)}
                            </div>

                            {data?.igst ? (
                              <React.Fragment>
                                <div className="col-lg-8">IGST</div>
                                <div className="col-lg-4 text-end">
                                  (+) {Currency(data?.igst)}
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}

                            {data?.cgst ? (
                              <React.Fragment>
                                <div className="col-lg-8">CGST</div>
                                <div className="col-lg-4 text-end">
                                  (+) {Currency(data?.cgst)}
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}

                            {data?.sgst ? (
                              <React.Fragment>
                                <div className="col-lg-8">SGST</div>
                                <div className="col-lg-4 text-end">
                                  (+) {Currency(data?.sgst)}
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}

                            {data?.discount ? (
                              <React.Fragment>
                                <div className="col-lg-8">Discount</div>
                                <div className="col-lg-4 text-end">
                                  (-) {Currency(data?.discount)}
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}

                            <div className="col-lg-8 fw-bold">Net Price</div>
                            <div className="col-lg-4 text-end fw-bold">
                              {Currency(data?.totalPrice)}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {loading && <WebLoader />}
    </div>
  );
};

export default InvoiceDetails;
