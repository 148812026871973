import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import AppHeader from "./components/app-header";
import AppFooter from "./components/app-footer";
import AppSidebar from "./components/app-sidebar";
import Leads from "./screens/leads";
import Customers from "./screens/customers";
import ManagePages from "./screens/website/manage-pages";
import ManageWebsite from "./screens/website/manage-website";
import Settings from "./screens/settings";
import QuickProposals from "./screens/quick-proposals";
import Invoices from "./screens/invoices";
import Inventory from "./screens/inventory";

function AppAdmin() {
  const [portal, setPortal] = useState("");
  const { Content } = Layout;

  return (
    <Layout>
      <AppHeader setPortal={setPortal} />
      <Layout>
        <AppSidebar />
        <Content>
          <Routes>
            <Route key={1} path="/customers" element={<Customers />} />
            <Route key={2} path="/leads" element={<Leads {...portal} />} />
            <Route
              key={3}
              path="/proposals"
              element={<QuickProposals {...portal} />}
            />
            <Route
              key={4}
              path="/manage-website"
              element={<ManageWebsite {...portal} />}
            />
            <Route key={5} path="/pages" element={<ManagePages />} />
            <Route
              key={6}
              path="/invoices"
              element={<Invoices {...portal} />}
            />
            <Route
              key={7}
              path="/vouchers"
              element={<Invoices key={window.location.pathname} {...portal} />}
            />
            <Route
              key={9}
              path="/inventory"
              element={<Inventory key={window.location.pathname} {...portal} />}
            />
            <Route
              key={10}
              path="/settings"
              element={<Settings {...portal} />}
            />
          </Routes>
        </Content>
      </Layout>
      <AppFooter />
    </Layout>
  );
}

export default AppAdmin;
