import React from "react";
import BannerBg from "../assets/images/public/banner-bg.png";
import TargetIcon1 from "../assets/images/public/target-icon-1.png";
import TargetIcon2 from "../assets/images/public/target-icon-2.png";
import TargetIcon3 from "../assets/images/public/target-icon-3.png";
import TargetIcon4 from "../assets/images/public/target-icon-4.png";
import ClipWebsite1 from "../assets/images/public/clip-websitebuilder.jpg";
import ClipWebsite2 from "../assets/images/public/clip-crm.jpg";
import ClipWebsite3 from "../assets/images/public/clip-invoicing.jpg";
import ClipWebsite4 from "../assets/images/public/clip-proposals.jpg";
import ClipWebsite5 from "../assets/images/public/clip-promotions.jpg";

const HomePublic = () => {
  return (
    <div className="public-home">
      <div className="public-home-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Take Your Business Online with BizVeda</h2>

              <h3>
                Elevate Your Brand, Delight Clients and Capture New
                Opportunities!
              </h3>

              <ul>
                <li>
                  Easily reach your customers with the BizVeda CRM module any
                  time from any device
                </li>
                <li>
                  Showcase what you sell through BizVeda's simple to use Website
                  builder
                </li>
                <li>
                  Promote your products and promotions online using WhatsApp and
                  social media
                </li>
                <li>
                  Never lose an Invoice or a payment using our Invoicing
                  platform
                </li>
                <li>
                  Worried about your data, no problem!!, BizVeda can be
                  installed on your servers
                </li>
                <li>
                  Want to make some changes, no issues, we provide customization
                  services
                </li>
              </ul>
            </div>
          </div>
        </div>
        <img className="public-home-banner-bg" src={BannerBg} alt="" />
      </div>
      <div className="public-home-users">
        <div className="container">
          <h2>Who BizVeda Is For</h2>
          <h5>
            Our solutions are ideal for Travel professionals, startups or small
            business owners
          </h5>
          <div className="row mt-5">
            <div className="col-lg-3">
              <div className="public-home-users-wrap public-home-users-wrap-1">
                <img src={TargetIcon1} alt="" />
                <h5>Want to show their products and promotions online</h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="public-home-users-wrap public-home-users-wrap-2">
                <img src={TargetIcon2} alt="" />
                <h5>Easily market them via WhatsApp and social media</h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="public-home-users-wrap public-home-users-wrap-3">
                <img src={TargetIcon3} alt="" />
                <h5>Affordability is an important criterion</h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="public-home-users-wrap public-home-users-wrap-4">
                <img src={TargetIcon4} alt="" />
                <h5>Simple to use and easy to manage</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="public-home-modules">
        <div className="container">
          <div>
            <div className="row">
              <div className="col-lg-6">
                <img src={ClipWebsite1} alt="" />
              </div>
              <div className="col-lg-6">
                <h2>Exciting Website Builder</h2>
                <h3>
                  Get ready to launch a stunning website in minutes and showcase
                  your packages, deals, and promotions effortlessly with our
                  user-friendly tools. All it takes is selecting a template and
                  entering some information, and voila! Your website is live in
                  no time.
                </h3>
                <h4>Key Features:</h4>
                <ul>
                  <li>
                    Simple and intuitive interface. No technical expertise
                    required!
                  </li>
                  <li>
                    Simply input your deal or promotion details, upload images,
                    and watch as Bizveda automatically generates stunning pages
                    for each. Let Bizveda do the heavy lifting!
                  </li>
                  <li>
                    Promote your deals and promotions across WhatsApp,
                    Instagram, Facebook, and more using our built-in tools
                  </li>
                  <li>
                    Impress your customers and watch as your business grows with
                    new referrals pouring in.
                  </li>
                </ul>
                <h4 className="mt-4">
                  Experience the thrill of creating your dream website with
                  BizVeda's Simple Website Builder Solution!
                </h4>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="row">
              <div className="col-lg-6">
                <h2>Simple CRM</h2>
                <h3>
                  Leave a lasting impression on your clients and attract new
                  customers effortlessly.
                </h3>
                <h4>Key Features:</h4>
                <ul>
                  <li>
                    <b>Fast and Quick Inquiry Management:</b> Auto-register
                    website inquiries, easily handle walk-ins or phone inquiries
                    with our quick entry form. Assign to employees and receive
                    progress reminders automatically.
                  </li>
                  <li>
                    <b>Streamlined Conversation Tracking:</b> Consolidate all
                    client communications in one place, akin to a chat
                    interface. Customize and reuse conversations for enhanced
                    efficiency.
                  </li>
                  <li>
                    <b>Centralized Information Hub:</b> Store customer details
                    and conversations in one accessible location, available 24/7
                    on any device.
                  </li>
                  <li>
                    <b>User-Friendly Interface:</b> Clearly understandable
                    functionality designed for time-saving ease of use
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <img src={ClipWebsite2} alt="" />
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="row">
              <div className="col-lg-6">
                <img src={ClipWebsite3} alt="" />
              </div>
              <div className="col-lg-6">
                <h2>Effortless Invoicing System</h2>
                <h3>
                  Now, travel agents can effortlessly create GST/VAT/Tax-ready
                  invoices with a simple click!
                  <br />
                  With our Invoicing module, you can easily generate, print, and
                  share invoices with customers via WhatsApp, email, or text.
                  Include all essential details like services, prices, and guest
                  information for accuracy.
                </h3>
                <h4>Key Features:</h4>
                <ul>
                  <li>
                    Create and manage and store invoices conveniently in one
                    place.
                  </li>
                  <li>
                    Stay organized by tracking overdue and pending invoices and
                    taxes.
                  </li>
                  <li>
                    Never miss payments with clear indicators for paid or unpaid
                    invoices.
                  </li>
                  <li>
                    Gain insights by linking invoices to inquiries and proposals
                    to track conversion rates.
                  </li>
                </ul>
                <h4 className="mt-4">
                  Streamline your invoicing process and ensure seamless
                  transactions with BizVeda's Invoicing System!
                </h4>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="row">
              <div className="col-lg-6">
                <h2>Proposal/Quotation Management</h2>
                <h3>
                  Experience the convenience and efficiency of Biveda's
                  Proposal/Quotation Management System!
                </h3>
                <h4>Key Features:</h4>
                <ul>
                  <li>
                    <b>Effortless Creation:</b> Quickly tailor proposals to your
                    clients' needs with our user-friendly system. Add
                    attachments to enhance your offerings and impress your
                    clients with personalized proposals.
                  </li>
                  <li>
                    <b>Streamlined Communication:</b> Say goodbye to scattered
                    messages. All client interactions are centralized, making it
                    easy to track inquiries, proposals revisions, and approvals
                    in one place.
                  </li>
                  <li>
                    <b>Efficient teamwork:</b> Assign tasks to employees, set
                    deadlines, and track progress in real-time, ensuring timely
                    delivery of proposals to your clients.
                  </li>
                  <li>
                    <b>Consistent Branding:</b> Save time and maintain your
                    brand identity with customizable templates for your
                    proposals and quotations.
                  </li>
                  <li>
                    <b>Enhanced Client Satisfaction:</b> Enjoy a seamless
                    experience from inquiry to booking, building trust and
                    loyalty with your clients.
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <img src={ClipWebsite4} alt="" />
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="row">
              <div className="col-lg-6">
                <img src={ClipWebsite5} alt="" />
              </div>
              <div className="col-lg-6">
                <h2>Promotion Management</h2>
                <h3>
                  Effortless Promotion Creation: Easily create visually
                  captivating landing pages for promotions using provided
                  templates. Simply add text, images, and attachments.
                  <br />
                  Seamless Customer Connection: Share your promotion with
                  customers via email, WhatsApp, or text with just a few clicks.
                </h3>
                <h4>Key Features:</h4>
                <ul>
                  <li>
                    Create your promotion by adding text, images, and
                    attachments.
                  </li>
                  <li>
                    The system automatically generates a visually appealing
                    webpage and provides you with a link.
                  </li>
                  <li>
                    Share the link with customers via email, WhatsApp, or text.
                  </li>
                  <li>
                    Customers click the link to view the promotion details on a
                    beautifully crafted webpage.
                  </li>
                  <li>
                    Interested customers can fill out an inquiry form directly
                    from the webpage and details are captured into the system
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePublic;
