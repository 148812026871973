import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Row,
  Col,
  Radio,
  message,
  Drawer,
  Table,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";
import dayjs from "dayjs";
import CustomersDropDown from "./customers-dropdown";
import { invoiceStatusList, paymentStatusList } from "../helpers/lookups";
import InvoiceItemAdd from "./invoice-item-add";
import InvoicePaymentAdd from "./invoice-payment-add";
import { Date, Currency, Time, DateTime, ResDate } from "../helpers/formatter";

const InvoiceAdd = (props) => {
  const [data, setData] = useState("");
  const [visible, setVisible] = useState(false);
  const [visiblePayment, setVisiblePayment] = useState(false);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invoicePayments, setInvoicePayments] = useState([]);
  const [itemKey, setItemKey] = useState(1);
  const [paymentKey, setPaymentKey] = useState(1);
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [customerType, setCustomerType] = useState("Individual");
  const [itemBusiness, setItemBusiness] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [details, setDetails] = useState();
  const [isEditModePayment, setIsEditModePayment] = useState(false);
  const [detailsPayment, setDetailsPayment] = useState();
  const [itemType, setItemType] = useState("");
  const [portal] = useState(props?.portal);
  const [type] = useState(!props?.isConvertMode ? props?.type : "Invoice");

  const getData = () => {
    const { invoiceId } = props;

    let reqURL = "invoices/detail/" + invoiceId;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data || "");
          setDefaultData(data || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      invoiceFor: data?.invoiceFor || "",
      customerId: data?.customerId || "",
      invoiceTitle: data?.invoiceTitle || "",
      invoiceStatus: data?.invoiceStatus || "Draft",
      invoiceNumber: data?.invoiceNumber || "",
      invoiceDate: data?.invoiceDate && dayjs(data?.invoiceDate),
      voucherDate: data?.voucherDate && dayjs(data?.voucherDate),
      invoiceDueDate: data?.invoiceDueDate && dayjs(data?.invoiceDueDate),
      invoiceFromDate: data?.invoiceFromDate && dayjs(data?.invoiceFromDate),
      invoiceToDate: data?.invoiceToDate && dayjs(data?.invoiceToDate),
      paymentStatus: data?.paymentStatus || "",
      assignedTo: data?.assignedTo || "",
    });
    setInvoiceItems(data?.invoiceItems || []);
    setInvoicePayments(data?.invoicePayments || []);
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL =
      "invoices/" +
      (!props?.isEditMode
        ? "add"
        : !props?.isConvertMode
        ? "update"
        : "convert");
    let reqOBJ = values;

    reqOBJ.type = type;
    reqOBJ.invoiceNumberPrefix = portal?.invoiceNumberPrefix || "Inv-";
    reqOBJ.voucherNumberPrefix = portal?.voucherNumberPrefix || "Vcr-";
    reqOBJ.invoiceDate = ResDate(values?.invoiceDate);
    reqOBJ.invoiceDueDate = ResDate(values?.invoiceDueDate);
    reqOBJ.voucherDate = ResDate(values?.voucherDate);

    if (props?.isEditMode && type === "Voucher") {
      reqOBJ.type = data?.type;
      reqOBJ.invoiceDate = ResDate(data?.invoiceDate);
      reqOBJ.invoiceDueDate = ResDate(data?.invoiceDueDate);
      reqOBJ.invoiceStatus = data?.invoiceStatus;
    }

    if (props?.isEditMode && type === "Invoice") {
      reqOBJ.type = data?.type;
      reqOBJ.voucherDate = ResDate(data?.voucherDate);
      reqOBJ.invoiceStatus = values?.invoiceStatus;
    }

    if (props?.isConvertMode) {
      reqOBJ.type = "Invoice";
      reqOBJ.invoiceStatus = values?.invoiceStatus;
      reqOBJ.invoiceDate = ResDate(values?.invoiceDate);
      reqOBJ.invoiceDueDate = ResDate(values?.invoiceDueDate);
    }

    reqOBJ.invoiceItems = invoiceItems;
    reqOBJ.invoicePayments = invoicePayments;
    props?.isEditMode && (reqOBJ.invoiceId = props?.invoiceId);

    calcInvoicePrices();

    reqOBJ = { ...reqOBJ, ...calcInvoicePrices() };

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);
      data?.invoiceId || data?.success
        ? props.handleAdd()
        : message.error("Oops! Something went wrong.");
    });
  };

  const calcInvoicePrices = () => {
    let iPrices = {
      invoiceAmount: 0,
      basePrice: 0,
      totalPrice: 0,
      costPrice: 0,
      sellPrice: 0,
      supplierCostPrice: 0,
      supplierTax: 0,
      markup: 0,
      discount: 0,
      igst: 0,
      cgst: 0,
      sgst: 0,
    };

    invoiceItems.map(
      (x) => (
        (iPrices.invoiceAmount = (x?.sellPrice || 0) + iPrices?.invoiceAmount),
        (iPrices.basePrice = (x?.basePrice || 0) + iPrices?.basePrice),
        (iPrices.totalPrice = (x?.totalPrice || 0) + iPrices?.totalPrice),
        (iPrices.costPrice = (x?.costPrice || 0) + iPrices?.costPrice),
        (iPrices.sellPrice = (x?.sellPrice || 0) + iPrices?.sellPrice),
        (iPrices.supplierCostPrice =
          (x?.supplierCostPrice || 0) + iPrices?.supplierCostPrice),
        (iPrices.supplierTax = (x?.supplierTax || 0) + iPrices?.supplierTax),
        (iPrices.markup = (x?.markup || 0) + iPrices?.markup),
        (iPrices.discount = (x?.discount || 0) + iPrices?.discount),
        (iPrices.igst = (x?.igst || 0) + iPrices?.igst),
        (iPrices.cgst = (x?.cgst || 0) + iPrices?.cgst),
        (iPrices.sgst = (x?.sgst || 0) + iPrices?.sgst)
      )
    );

    return iPrices;
  };

  const handleValuesChange = (value, values) => {
    if (value["invoiceDate"]) {
      form.setFieldsValue({
        invoiceDueDate: dayjs(value["invoiceDate"]).add(1, "day"),
      });
    }
  };

  const handleCustomer = (customer) => {
    form.setFieldsValue({ customerId: customer });
  };

  const handleAddItem = (item) => {
    invoiceItems.push(item);
    setInvoiceItems(invoiceItems);
    onClose();
  };

  const handleEditItem = (item) => {
    setDetails(item);
    setIsEditMode(true);
    setItemKey(itemKey + 1);
    setItemBusiness(item?.business);
    setVisible(true);
  };

  const handleUpdateItem = (item) => {
    let updatedInvoiceItems = invoiceItems.map((x) =>
      x?.guId === item?.guId && x?.invoiceItemId === item?.invoiceItemId
        ? item
        : x
    );
    setItemKey(itemKey + 1);
    setInvoiceItems(updatedInvoiceItems);
    onClose();
  };

  const handleDeleteItem = (item) => {
    let invoiceNewItems = invoiceItems.filter(
      (x) => x?.guId !== item?.guId || x?.invoiceItemId !== item?.invoiceItemId
    );
    setInvoiceItems(invoiceNewItems);
  };

  const showDrawer = (business) => {
    setItemType(business);
    setIsEditMode(false);
    setDetails("");
    setItemKey(itemKey + 1);
    setItemBusiness(business);
    setVisible(true);
  };

  const onClose = () => {
    setIsEditMode(false);
    setDetails("");
    setItemKey(itemKey + 1);
    setVisible(false);
  };

  const showPaymentDrawer = () => {
    setIsEditModePayment(false);
    setDetailsPayment("");
    setPaymentKey(paymentKey + 1);
    setVisiblePayment(true);
  };

  const onPaymentClose = () => {
    setIsEditModePayment(false);
    setDetailsPayment("");
    setPaymentKey(paymentKey + 1);
    setVisiblePayment(false);
  };

  const handleAddPayment = (payment) => {
    invoicePayments.push(payment);
    setInvoicePayments(invoicePayments);
    setPaymentKey(paymentKey + 1);
    onPaymentClose();
  };

  const handleEditPayment = (payment) => {
    setDetailsPayment(payment);
    setIsEditModePayment(true);
    setPaymentKey(paymentKey + 1);
    setVisiblePayment(true);
  };

  const handleDeletePayment = (payment) => {
    let invoiceNewPayment = invoicePayments.filter(
      (x) =>
        x?.guId !== payment?.guId ||
        x?.invoicePaymentId !== payment?.invoicePaymentId
    );
    setInvoicePayments(invoiceNewPayment);
  };

  const handleUpdatePayment = (payment) => {
    let updatedInvoicePayments = invoicePayments.map((x) =>
      x?.guId === payment?.guId &&
      x?.invoicePaymentId === payment?.invoicePaymentId
        ? payment
        : x
    );
    setPaymentKey(paymentKey + 1);
    setInvoicePayments(updatedInvoicePayments);
    onPaymentClose();
  };

  const getInvoiceColumns = () => {
    let invoiceColumns = [
      {
        title: "Type",
        dataIndex: "business",
        key: "business",
        width: 74,
      },
      {
        title: "Details",
        dataIndex: "itemName",
        key: "itemName",
        render: (text, record) => (
          <React.Fragment>
            <Row>
              <Col span={10} style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <div>{record?.itemName}</div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      marginTop: "2px",
                      opacity: "0.6",
                    }}
                  >
                    {record?.itemLocation}
                  </div>
                </div>
              </Col>
              <Col span={14} style={{ display: "flex", alignItems: "center" }}>
                {record?.business === "Hotel" && (
                  <div>
                    <div style={{ fontSize: "0.8rem" }}>
                      {Date(record?.checkInDate)}
                      {" - "}
                      {Date(record?.checkOutDate)}
                    </div>

                    <div
                      style={{
                        fontSize: "0.8rem",
                        marginTop: "2px",
                        opacity: "0.6",
                      }}
                    >
                      {record?.noOfRooms} x {record?.roomType} - Room(s)
                    </div>
                  </div>
                )}
                {(record?.business === "Package" ||
                  record?.business === "Activity" ||
                  record?.business === "Other") && (
                  <div>
                    <div style={{ fontSize: "0.8rem" }}>
                      {Date(record?.checkInDate)}
                    </div>

                    <div
                      style={{
                        fontSize: "0.8rem",
                        marginTop: "2px",
                        opacity: "0.6",
                      }}
                    >
                      {record?.itemType}

                      {record?.business === "Package" &&
                        " - " + record?.duration}

                      {record?.business === "Activity" &&
                        " - " + record?.noOfGuests + " Guest(s)"}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            {record?.business === "Flight" && (
              <Row>
                <Col span={24}>
                  <div style={{ fontSize: "0.8rem" }}>
                    <div>
                      {record?.fromLocation} ({record?.fromLocationCode}){" - "}
                      {record?.toLocation} ({record?.toLocationCode})
                    </div>

                    <div
                      style={{
                        fontSize: "0.8rem",
                        marginTop: "2px",
                        opacity: "0.6",
                      }}
                    >
                      {record?.departAirline}
                      {" | "}
                      {record?.departDuration}
                      {" | "}
                      {record?.departClass}
                    </div>

                    {record?.tripType ? (
                      <React.Fragment>
                        <div className="mt-2">
                          {record?.toLocation} ({record?.toLocationCode}){" - "}
                          {record?.fromLocation} ({record?.fromLocationCode})
                        </div>

                        <div
                          style={{
                            fontSize: "0.8rem",
                            marginTop: "2px",
                            opacity: "0.6",
                          }}
                        >
                          {record?.returnAirline}
                          {" | "}
                          {record?.returnDuration}
                          {" | "}
                          {record?.departClass}
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Col>
              </Row>
            )}

            {record?.business === "Transfer" && (
              <Row>
                <Col span={24}>
                  <div>
                    <div>
                      {record?.pickupLocation} - {record?.dropoffLocation}
                    </div>
                    <div
                      style={{
                        fontSize: "0.8rem",
                        marginTop: "2px",
                        opacity: "0.6",
                      }}
                    >
                      {DateTime(record?.startTime)} - {Time(record?.endTime)}
                    </div>
                    <div
                      style={{
                        fontSize: "0.8rem",
                        marginTop: "2px",
                        opacity: "0.6",
                      }}
                    >
                      {record?.itemType} {record?.tripType}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </React.Fragment>
        ),
      },
      {
        title: "Price",
        dataIndex: "sellPrice",
        key: "sellPrice",
        render: (text) => Currency(text || 0),
        width: 100,
      },
      {
        title: "Actions",
        render: (text, record) => (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              className="reset-icon-btn"
              icon={<EditOutlined />}
              onClick={() => handleEditItem(record)}
            />
            <Button
              className="reset-icon-btn"
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteItem(record)}
            />
          </div>
        ),
        width: 50,
      },
    ];

    if (props?.isConvertMode) {
      invoiceColumns = invoiceColumns.filter((x) => x?.title !== "Actions");
    }
    return invoiceColumns;
  };

  const paymentColumns = [
    {
      title: "Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (text) => Date(text),
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text) => Currency(text || 0),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
    {
      title: "Payment Gateway",
      dataIndex: "paymentGateway",
      key: "paymentGateway",
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button
            className="reset-icon-btn"
            icon={<EditOutlined />}
            onClick={() => handleEditPayment(record)}
          />
          <Button
            className="reset-icon-btn"
            icon={<DeleteOutlined />}
            onClick={() => handleDeletePayment(record)}
          />
        </div>
      ),
      width: 50,
    },
  ];

  const col1 = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const col2 = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const col3 = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };

  useEffect(() => {
    props?.isEditMode && getData();
  }, []);

  return (
    <React.Fragment>
      <Form
        name="invoice-add"
        form={form}
        {...col1}
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={handleValuesChange}
        initialValues={{
          invoiceFor: "Individual",
          invoiceStatus: "Draft",
          paymentStatus: "Pending",
          invoiceDate: dayjs(),
          invoiceDueDate: dayjs().add(1, "day"),
          invoiceFromDate: dayjs(),
          invoiceToDate: dayjs(),
          voucherDate: dayjs(),
          assignedTo: "Sales",
        }}
      >
        <Row>
          <Col xs={24} xl={24}>
            <Form.Item label={type + " For"} name="invoiceFor" hidden>
              <Radio.Group>
                <Radio.Button value="Individual">Individual</Radio.Button>
                <Radio.Button value="Corporate">Corporate</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} xl={24}>
            <Form.Item
              label="Customer"
              name="customerId"
              rules={[
                {
                  required: true,
                  message: "Please Select Customer!",
                },
              ]}
            >
              <CustomersDropDown
                key={props?.customerId}
                {...props}
                customerType={customerType}
                handleCustomer={handleCustomer}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} xl={12}>
            <Form.Item label={type + " Title"} name="invoiceTitle" {...col2}>
              <Input placeholder={"Enter " + type + " Title"} />
            </Form.Item>
          </Col>

          {type === "Invoice" && (
            <Col xs={24} xl={12}>
              <Form.Item label="Status" name="invoiceStatus" {...col3}>
                <Select
                  placeholder="Select Payment Status"
                  options={invoiceStatusList}
                ></Select>
              </Form.Item>
            </Col>
          )}

          {type === "Voucher" && (
            <Col xs={24} xl={12}>
              <Form.Item label={type + " Date"} name="voucherDate" {...col3}>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row>
          <Col xs={24} xl={12}>
            <Form.Item label="Number" name="invoiceNumber" {...col3} hidden>
              <Input placeholder="Auto Generated" disabled />
            </Form.Item>
          </Col>

          <Col xs={24} xl={12}>
            <Form.Item label="Payment" name="paymentStatus" {...col3} hidden>
              <Select
                placeholder="Select Payment Status"
                options={paymentStatusList}
              ></Select>
            </Form.Item>
          </Col>
        </Row>

        {type === "Invoice" && (
          <Row>
            <Col xs={24} xl={12}>
              <Form.Item label={type + " Date"} name="invoiceDate" {...col2}>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  allowClear={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} xl={12}>
              <Form.Item label="Due Date" name="invoiceDueDate" {...col3}>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  allowClear={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={24} xl={12}>
            <Form.Item
              label="From Date"
              name="invoiceFromDate"
              {...col2}
              hidden
            >
              <DatePicker format={"DD/MM/YYYY"} allowClear={false} />
            </Form.Item>
          </Col>

          <Col xs={24} xl={12}>
            <Form.Item label="To Date" name="invoiceToDate" {...col2} hidden>
              <DatePicker format={"DD/MM/YYYY"} allowClear={false} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} xl={12}>
            <Form.Item label="Assign To" name="assignedTo" {...col2} hidden>
              <Select>
                <Select.Option value="sales">Sales</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Add Items">
          <Button
            onClick={() => showDrawer("Package")}
            disabled={props?.isConvertMode}
          >
            Package
          </Button>
          <Button
            onClick={() => showDrawer("Hotel")}
            style={{ marginLeft: "8px" }}
            disabled={props?.isConvertMode}
          >
            Hotel
          </Button>
          <Button
            onClick={() => showDrawer("Flight")}
            style={{ marginLeft: "8px" }}
            disabled={props?.isConvertMode}
          >
            Flight
          </Button>
          <Button
            onClick={() => showDrawer("Activity")}
            style={{ marginLeft: "8px" }}
            disabled={props?.isConvertMode}
          >
            Activity
          </Button>

          <Button
            onClick={() => showDrawer("Transfer")}
            style={{ marginLeft: "8px" }}
            disabled={props?.isConvertMode}
          >
            Transfer
          </Button>
          <Button
            onClick={() => showDrawer("Other")}
            style={{ marginLeft: "8px" }}
            disabled={props?.isConvertMode}
          >
            Other
          </Button>
          {invoiceItems && invoiceItems.length > 0 && (
            <Table
              columns={getInvoiceColumns()}
              rowKey={(record) => record?.guId || record?.invoiceItemId}
              dataSource={invoiceItems || []}
              size="small"
              key={itemKey}
              pagination={false}
              style={{ marginTop: "16px" }}
              bordered
            />
          )}
        </Form.Item>

        {type === "Invoice" && !props?.isConvertMode && (
          <Form.Item label="Add Payments">
            <Button icon={<PlusOutlined />} onClick={showPaymentDrawer}>
              Add Payment
            </Button>
            {invoicePayments && invoicePayments.length > 0 && (
              <Table
                columns={paymentColumns}
                rowKey={(record) => record?.guId || record?.invoicePaymentId}
                dataSource={invoicePayments || []}
                size="small"
                key={paymentKey}
                pagination={false}
                style={{ marginTop: "16px" }}
                bordered
              />
            )}
          </Form.Item>
        )}

        <Form.Item
          wrapperCol={{
            offset: 5,
            span: 18,
          }}
          className="btn-row"
          style={{ marginTop: "24px" }}
        >
          <Button
            icon={!props?.isEditMode ? <PlusOutlined /> : <EditOutlined />}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {!props?.isEditMode
              ? "Add " + type
              : !props?.isConvertMode
              ? "Update " + type
              : "Convert to Invoice"}
          </Button>
        </Form.Item>
      </Form>

      <Drawer
        title={(!isEditMode ? "Add " : "Update ") + itemType}
        placement="right"
        onClose={onClose}
        open={visible}
        size="large"
      >
        <InvoiceItemAdd
          isEditMode={isEditMode}
          {...details}
          business={itemBusiness}
          key={itemKey}
          handleAddItem={handleAddItem}
          handleUpdateItem={handleUpdateItem}
        />
      </Drawer>

      <Drawer
        title={"Add Payment"}
        placement="right"
        onClose={onPaymentClose}
        open={visiblePayment}
        width={520}
      >
        <InvoicePaymentAdd
          isEditModePayment={isEditModePayment}
          {...detailsPayment}
          invoiceTotal={data?.invoiceAmount}
          key={paymentKey}
          handleAddPayment={handleAddPayment}
          handleUpdatePayment={handleUpdatePayment}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default InvoiceAdd;
