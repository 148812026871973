import React from "react";
import { defaultSettings } from "../../helpers/default-settings";

const WebsiteFooter = (props) => {
  return (
    <footer className="web-footer">
      <div className="container">
        <div className="text-center text-secondary small">
          {props?.copyright || defaultSettings?.copyright}
        </div>
      </div>
    </footer>
  );
};

export default WebsiteFooter;
