import React from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import AppFooter from "./components/app-footer";
import AppHeaderPublic from "./components/app-header-public";
import "./assets/css/style-public.css";
import SignIn from "./screens/signin";
import SignUp from "./screens/signup";
import HomePublic from "./screens/home-public";
import AboutUs from "./screens/about-us";

function AppPublic() {
  const { Content } = Layout;

  return (
    <Layout>
      <AppHeaderPublic />
      <Layout>
        <Content>
          <Routes>
            <Route key={0} path="" element={<HomePublic />} />
            <Route key={1} path="/signin" element={<SignIn />} />
            <Route key={2} path="/signup" element={<SignUp />} />
            <Route key={3} path="/about-us" element={<AboutUs />} />
          </Routes>
        </Content>
      </Layout>
      <AppFooter />
    </Layout>
  );
}

export default AppPublic;
