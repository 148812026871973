import React, { useEffect, useState } from "react";
import { Button, Dropdown, Drawer, Modal, message, Table, Tabs } from "antd";
import {
  DatabaseOutlined,
  PlusOutlined,
  MenuOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import fetchData from "../services/requester";
import CommonFilters from "../components/common-filters";
import { Date, Currency, Time, DateTime } from "../helpers/formatter";
import InvoiceItemAdd from "../components/invoice-item-add";

const Inventory = (props) => {
  const [data, setData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const [isConfirmLoading, setConfirmLoading] = useState(false);
  const [isFiltersVisible, setFiltersVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [business, setBusiness] = useState("Package");

  const getData = (business) => {
    let reqURL = "inventory";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data.filter((x) => x?.business === business) || []);
        setDefaultData(data || []);
        setLoading(false);
      },
      "GET"
    );
  };

  const menuItems = [
    {
      label: "Edit Item",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Delete Item",
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];

  const handleMenuClick = (key, item) => {
    setDetails(item);
    switch (key) {
      case "view":
        handlePreview(item);
        break;

      case "edit":
        setIsEditMode(true);
        showDrawer();
        break;

      case "delete":
        setConfirmVisible(true);
        break;

      default:
        break;
    }
  };

  const onClose = () => {
    setIsEditMode(false);
    setVisible(false);
  };

  const onFiltersClose = () => {
    setFiltersVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const showFiltersDrawer = () => {
    setFiltersVisible(true);
  };

  const handleAdd = (response) => {
    message.success(
      !isEditMode ? "Item added successfully" : "Item updated successfully"
    );
    setVisible(false);
    setIsEditMode(false);
    getData(business);
  };

  const handleDelete = () => {
    setConfirmLoading(true);
    const { inventoryItemId } = details;
    let reqURL = "inventory/delete/" + inventoryItemId;
    let reqOBJ = "";
    fetchData(reqURL, reqOBJ, (data) => {
      setConfirmVisible(false);
      setConfirmLoading(false);
      if (data?.success) {
        message.success("Item deleted successfully.");
        setLoading(true);
        getData(business);
      } else {
        message.error(data?.title || "Oops! Something went wrong.");
      }
    });
  };

  const handleFilters = (filters) => {
    setFiltersVisible(false);

    const filteredData = defaultData?.filter(
      ({ invoiceTitle, bookingFor, name, email, phone }) =>
        (!filters?.title ||
          invoiceTitle
            ?.toLowerCase()
            ?.includes(filters?.title?.toLowerCase())) &&
        (!filters?.bookingFor ||
          bookingFor
            ?.toLowerCase()
            ?.includes(filters?.bookingFor?.toLowerCase())) &&
        (!filters?.name ||
          name?.toLowerCase()?.includes(filters?.name?.toLowerCase())) &&
        (!filters?.email ||
          email?.toLowerCase()?.includes(filters?.email?.toLowerCase())) &&
        (!filters?.phone || phone?.includes(filters?.phone))
    );

    setData(filteredData);
  };

  const handlePreview = (item) => {
    const { url } = props;
    const { invoiceId } = item;
    let path = "/website/" + url + "/invoice-details/" + invoiceId;

    window.open(window.origin + path, "_blank");
  };

  const getColumns = (business) => {
    let columns = [];
    if (business === "Package") {
      columns = [
        {
          title: "Name",
          dataIndex: "itemName",
          key: "itemName",
          width: 220,
        },
        {
          title: "Destination",
          dataIndex: "itemLocation",
          key: "itemLocation",
          width: 220,
        },
        {
          title: "Type",
          dataIndex: "itemType",
          key: "itemType",
        },
        {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
        },
      ];
    }

    if (business === "Hotel") {
      columns = [
        {
          title: "Name",
          dataIndex: "itemName",
          key: "itemName",
          width: 220,
        },
        {
          title: "Location",
          dataIndex: "itemLocation",
          key: "itemLocation",
          width: 220,
        },
        {
          title: "Address",
          dataIndex: "itemAddress",
          key: "itemAddress",
        },
        {
          title: "Star Rating",
          dataIndex: "starRating",
          key: "starRating",
          render: (text) => text + " Stars",
        },
        {
          title: "Room Type",
          dataIndex: "roomType",
          key: "roomType",
        },
      ];
    }

    if (business === "Activity") {
      columns = [
        {
          title: "Name",
          dataIndex: "itemName",
          key: "itemName",
          width: 220,
        },
        {
          title: "Location",
          dataIndex: "itemLocation",
          key: "itemLocation",
          width: 220,
        },
        {
          title: "Address",
          dataIndex: "itemAddress",
          key: "itemAddress",
        },
        {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
        },
        {
          title: "Type",
          dataIndex: "itemType",
          key: "itemType",
        },
      ];
    }

    if (business === "Transfer") {
      columns = [
        {
          title: "Pickup Location",
          dataIndex: "pickupLocation",
          key: "pickupLocation",
        },
        {
          title: "Dropoff Location",
          dataIndex: "dropoffLocation",
          key: "dropoffLocation",
        },
        {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
        },
        {
          title: "Vehicle Type",
          dataIndex: "itemType",
          key: "itemType",
        },
      ];
    }

    if (business === "Other") {
      columns = [
        {
          title: "Name",
          dataIndex: "itemName",
          key: "itemName",
          width: 220,
        },
        {
          title: "Location",
          dataIndex: "itemLocation",
          key: "itemLocation",
          width: 220,
        },
        {
          title: "Address",
          dataIndex: "itemAddress",
          key: "itemAddress",
        },
        {
          title: "Type",
          dataIndex: "itemType",
          key: "itemType",
        },
      ];
    }

    if (business === "Flight") {
      columns = [
        {
          title: "Rrip Type",
          dataIndex: "tripType",
          key: "tripType",
          render: (text) => (text ? "Round Trip" : "One Way"),
          width: 120,
        },
        {
          title: "From",
          dataIndex: "fromLocation",
          key: "fromLocation",
          render: (text, record) =>
            record?.fromLocation +
            (record?.fromLocationCode &&
              " (" + record?.fromLocationCode + ") "),
        },
        {
          title: "To",
          dataIndex: "toLocation",
          key: "toLocation",
          render: (text, record) =>
            record?.toLocation +
            (record?.toLocation && " (" + record?.toLocation + ") "),
        },
        {
          title: "Airline",
          dataIndex: "departAirline",
          key: "departAirline",
          render: (text, record) =>
            record?.departAirline +
            (record?.tripType && " - " + record?.returnAirline),
        },
        {
          title: "Flight Number",
          dataIndex: "departFlightNumber",
          key: "departFlightNumber",
          render: (text, record) =>
            record?.departFlightNumber +
            (record?.tripType && " - " + record?.returnFlightNumber),
        },
        {
          title: "Class",
          dataIndex: "departClass",
          key: "departClass",
          render: (text, record) =>
            record?.departClass +
            (record?.tripType &&
              record?.returnClass &&
              " - " + record?.returnClass),
        },
        {
          title: "Stops",
          dataIndex: "departStops",
          key: "departStops",
          render: (text, record) =>
            record?.departStops +
            (record?.tripType && " - " + (record?.returnStops + " Stop(s)")),
        },
      ];
    }

    columns.push(
      {
        title: "Supplier",
        dataIndex: "supplier",
        key: "supplier",
        width: 150,
      },
      {
        title: "Sell Price",
        dataIndex: "sellPrice",
        key: "sellPrice",
        render: (text) => Currency(text || 0),
        width: 150,
      },
      {
        title: "Cost Price",
        dataIndex: "costPrice",
        key: "costPrice",
        render: (text) => Currency(text || 0),
        width: 150,
      },
      {
        title: "Actions",
        render: (text, record) => (
          <div className="d-flex align-items-center justify-content-center">
            <Dropdown
              menu={{
                items: menuItems,
                onClick: (e) => handleMenuClick(e.key, record),
              }}
              icon
            >
              <Button className="reset-icon-btn">
                <MenuOutlined />
              </Button>
            </Dropdown>
          </div>
        ),
        width: 50,
      }
    );

    return columns;
  };

  const items = [
    {
      key: "Package",
      label: "Packages",
      children: (
        <Table
          columns={getColumns("Package")}
          rowKey={(record) => record?.inventoryItemId}
          dataSource={data || []}
          loading={loading}
          size="small"
        />
      ),
    },
    {
      key: "Hotel",
      label: "Hotels",
      children: (
        <Table
          columns={getColumns("Hotel")}
          rowKey={(record) => record?.inventoryItemId}
          dataSource={data || []}
          loading={loading}
          size="small"
        />
      ),
    },
    {
      key: "Flight",
      label: "Flights",
      children: (
        <Table
          columns={getColumns("Flight")}
          rowKey={(record) => record?.inventoryItemId}
          dataSource={data || []}
          loading={loading}
          size="small"
        />
      ),
    },
    {
      key: "Activity",
      label: "Activity",
      children: (
        <Table
          columns={getColumns("Activity")}
          rowKey={(record) => record?.inventoryItemId}
          dataSource={data || []}
          loading={loading}
          size="small"
        />
      ),
    },
    {
      key: "Transfer",
      label: "Transfer",
      children: (
        <Table
          columns={getColumns("Transfer")}
          rowKey={(record) => record?.inventoryItemId}
          dataSource={data || []}
          loading={loading}
          size="small"
        />
      ),
    },
    {
      key: "Other",
      label: "Other",
      children: (
        <Table
          columns={getColumns("Other")}
          rowKey={(record) => record?.inventoryItemId}
          dataSource={data || []}
          loading={loading}
          size="small"
        />
      ),
    },
  ];

  const onChange = (key) => {
    setBusiness(key);
    let data = defaultData.filter((x) => x?.business === key);
    setData(data);
  };

  useEffect(() => {
    getData(business);
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <React.Fragment>
            <DatabaseOutlined style={{ marginRight: "4px" }} /> Inventory
          </React.Fragment>
        }
        extra={[
          // <Tooltip title="Filters" placement="bottom" key={2}>
          //   <Button
          //     key={2}
          //     type="default"
          //     icon={<FilterOutlined />}
          //     onClick={showFiltersDrawer}
          //   >
          //     Filters
          //   </Button>
          // </Tooltip>,
          <Button
            key={3}
            type="primary"
            icon={<PlusOutlined />}
            onClick={showDrawer}
          >
            Add {business}
          </Button>,
        ]}
        ghost={false}
        style={{ border: "0px" }}
      />

      <div className="website-admin">
        <div>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      </div>

      <Drawer
        title={!isEditMode ? "Add " + business : "Update " + business}
        placement="right"
        onClose={onClose}
        open={visible}
        width={"48%"}
      >
        <InvoiceItemAdd
          isEditMode={isEditMode}
          from="inventory"
          {...details}
          business={business}
          key={visible}
          handleAddInventoryItem={handleAdd}
          handleUpdateInventoryItem={handleAdd}
          handleAddItem={(item) => console.log(item)}
          handleUpdateItem={(item) => console.log(item)}
        />
      </Drawer>

      <Drawer
        title="Filters"
        placement="right"
        onClose={onFiltersClose}
        open={isFiltersVisible}
      >
        <CommonFilters handleFilters={handleFilters} screen={"leads"} />
      </Drawer>

      <Modal
        title={"Confirm Delete"}
        open={isConfirmVisible}
        onCancel={() => setConfirmVisible(false)}
        centered
        width={320}
        footer={[
          <div style={{ textAlign: "center" }} key="delete">
            <Button
              type="primary"
              loading={isConfirmLoading}
              danger
              onClick={() => handleDelete()}
            >
              Confirm
            </Button>
          </div>,
        ]}
      >
        <div style={{ margin: "16px 0px" }}>
          Are you sure to delete this Item?
        </div>
      </Modal>
    </div>
  );
};

export default Inventory;
