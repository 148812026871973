import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, message, Checkbox } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import fetchData from "../../services/requester";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadImg from "../upload-img";

const WhyusAdd = (props) => {
  const [data, setData] = useState("");
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const getData = () => {
    const { whyusId } = props;
    let reqURL = "whyus/detail/" + whyusId;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setDefaultData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      title: data?.title,
      subTitle: data?.subTitle,
      imageURL: data?.imageURL,
      description: data?.description,
      showOnHome: data?.showOnHome,
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "whyus/" + (props.isEditMode ? "update" : "add");
    let reqOBJ = values;

    props?.isEditMode && (reqOBJ.whyusId = props?.whyusId);

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);
      data?.success
        ? props.handleAdd()
        : message.error("Oops! Something went wrong.");
    });
  };

  const handleUpload = (imageURL) => {
    form.setFieldsValue({ imageURL });
  };

  useEffect(() => {
    props?.isEditMode && getData();
  }, []);

  return (
    <React.Fragment>
      <Form
        name="whyus-add"
        form={form}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 18,
        }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{ showOnHome: true }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input Page Title!",
                },
              ]}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
            <Form.Item label="Sub Title" name="subTitle">
              <Input placeholder="Enter Sub Title" />
            </Form.Item>
            <Form.Item label="Image" name="imageURL">
              <UploadImg
                path="uploadfile"
                type="file-input"
                handleUpload={handleUpload}
                image={data?.imageURL}
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                return data;
              }}
            >
              <CKEditor
                data={data?.description || ""}
                editor={ClassicEditor}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "280px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 5,
                span: 15,
              }}
              name="showOnHome"
              valuePropName="checked"
            >
              <Checkbox>Show on Home</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          wrapperCol={{
            offset: 5,
            span: 15,
          }}
          className="btn-row"
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            icon={!props?.isEditMode ? <PlusOutlined /> : <EditOutlined />}
          >
            {!props?.isEditMode ? "Add Whyus" : "Update Whyus"}
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default WhyusAdd;
