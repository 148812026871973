import React, { useEffect, useState } from "react";
import { Button, Descriptions, Divider, Typography } from "antd";
import fetchData from "../services/requester";
import { Date, Currency } from "../helpers/formatter";

const LeadView = (props) => {
  const [data, setData] = useState("");
  const { Text } = Typography;

  const getData = () => {
    const { leadId } = props;
    let reqURL = "leads/detail/" + leadId;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
        }
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <Divider
        orientation="left"
        orientationMargin="0"
        style={{ marginTop: "24px" }}
      >
        <Text style={{ fontWeight: "600" }}>Lead Details</Text>
      </Divider>
      <Descriptions column={3}>
        <Descriptions.Item label="Title">{data?.title}</Descriptions.Item>
        <Descriptions.Item label="Start Date">
          {Date(data?.startDate)}
        </Descriptions.Item>
        <Descriptions.Item label="Duration">
          {data?.duration + " day(s)"}
        </Descriptions.Item>
        <Descriptions.Item label="Trip Type">
          {data?.tripType}
        </Descriptions.Item>
        <Descriptions.Item label="Booking for">
          {data?.bookingFor}
        </Descriptions.Item>
        <Descriptions.Item label="Budget">
          {Currency(data?.budget)}
        </Descriptions.Item>
      </Descriptions>

      <Divider orientation="left" orientationMargin="0">
        <Text style={{ fontWeight: "600" }}>Customer Details</Text>
      </Divider>
      <Descriptions column={3}>
        <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
        <Descriptions.Item label="Email">{data?.email}</Descriptions.Item>
        <Descriptions.Item label="Phone">
          {data?.countryCode + data?.phone}
        </Descriptions.Item>
        <Descriptions.Item label="Adults">
          {data?.adults || 0}
        </Descriptions.Item>
        <Descriptions.Item label="Children">
          {data?.child || 0}
        </Descriptions.Item>
        <Descriptions.Item label="Infant">
          {data?.infant || 0}
        </Descriptions.Item>
      </Descriptions>

      <Divider orientation="left" orientationMargin="0">
        <Text style={{ fontWeight: "600" }}>Other Details</Text>
      </Divider>
      <Descriptions column={3}>
        <Descriptions.Item label="Lead Type">
          <Text type="success">{data?.leadType}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Assigned To">
          {data?.assignedTo}
        </Descriptions.Item>
        <Descriptions.Item label="Source">{data?.source}</Descriptions.Item>
        <Descriptions.Item label="Status">
          <Text type="success">{data?.status}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Followup Date">
          {Date(data?.followupDate)}
        </Descriptions.Item>
        <Descriptions.Item label="Created Date">
          {Date(data?.createdDate)}
        </Descriptions.Item>
      </Descriptions>

      {data?.comments && (
        <Descriptions column={1}>
          <Descriptions.Item label="Comments">
            {data?.comments}
          </Descriptions.Item>
        </Descriptions>
      )}
    </React.Fragment>
  );
};

export default LeadView;
